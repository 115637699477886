import React, {useState} from "react";
import styled from "styled-components";
import Input from "../Elements/Input";
import Button from "../Elements/Button";
import useInterval from "../CustomHooks/useInterval";
import {useRootStore} from "../RootStoreProvider";
import {useNavigate} from "react-router";
import routes from "../routes";

const Page = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    @media (max-width: 435px) {
      justify-content: flex-start;
    }
`;

const Title = styled.div`
    margin: 0;
    color: rgba(0, 0, 0, 0.9);
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    margin-bottom: 40px;
    
    @media (max-width: 435px) {
        font-size: 22px;
        margin-bottom: 24px;
    }
`;

const Subtitle = styled.div`
    margin: 0;
    color: rgba(0, 0, 0, 0.9);
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    width: 100%;
    margin-bottom: 40px;
    
    @media (max-width: 435px) {
        margin-bottom: 24px;
    }
`;

const Msg = styled.p`
    color: rgba(0, 0, 0, 0.5);
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    margin-bottom: 24px;
    margin-top: 0;
    
    b {
        color: rgba(0, 0, 0, 0.9);
    }
`;

const Time = styled.p`
    color: rgba(0, 0, 0, 0.9);
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 4px;
`;

const NewCode = styled.p`
    font-weight: 600;
    font-size: 16px;
    line-height: 160%;
    color: #FF5145;
    margin: 0 0 24px 0;
    opacity: ${({disabled}) => disabled ? 0.3 : 1};
    cursor: ${({disabled}) => disabled ? "default" : "cursor"};
`;

const Form = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 40px 30px;
    width: 436px;
    background: #FFFFFF;
    box-shadow: 0 4px 30px rgba(194, 201, 206, 0.35);
    border-radius: 24px;
    margin-top: 100px;
    margin-bottom: 20px;
    position: relative;
    z-index: 999;
    
    @media (max-width: 435px) {
        margin-top: 0;
        width: 100%;
        padding: 24px 16px;
        border-radius: 0 0 24px 24px;
    }
`;

const ErrorWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #D61C0F;
    margin-bottom: 8px;
`;

const Dot = styled.div`
    width: 6px;
    height: 6px;
    background: #D61C0F;
    margin-right: 8px;
    border-radius: 50px;
`;

/**
 * Подтверждение телефона
 */
const AcceptPhonePage = () => {
    const [code, setCode] = useState("");
    const [second, setSecond] = useState(300);
    const [error, setError] = useState(false);
    const store = useRootStore();
    const navigate = useNavigate();

    useInterval(() => setSecond(second - 1), 1000);

    /**
     * Конвертация количества секунд в человеческий вид
     */
    const convertSeconds = () => {
        const min = Math.floor(second / 60);
        const sec = second - (min*60);
        return `${min}:${sec.toString().length === 1 ? "0" : ""}${sec}`;
    };

    /**
     * Запрос нового кода
     */
    const getNewCode = () => {
        if (second <= 0) {
            setSecond(300);
            //запрос нового кода
            store.userStore.onAcceptPhoneNumber();
        }
    };


    /**
     * Подтверждение ввода кода
     */
    const onAccept = async () => {
        const result = await store.userStore.onConfirmPhone(navigate, code);
        if (result === 409) {
            setError(true);
        }
    };

    return (
        <Page>
            <Form>
                <Title>Информационные системы Московской области</Title>
                <Subtitle>Подтверждение номера мобильного телефона</Subtitle>
                <Msg>На ваш телефон <b>{store.userStore.showAcceptPhoneNumber}</b> направлено SMS-уведомление с кодом подтверждения</Msg>
                <Input placeholder="Введите код из SMS"
                       onChange={setCode}
                       fontSize={"24px"}
                       inputType={"code"}
                       height={"60px"}
                       isError={error}
                       textAlign={"center"}
                       value={code}/>
                {
                    error &&
                    <ErrorWrapper>
                        <Dot/>Неверный код
                    </ErrorWrapper>
                }
                {
                    second > 0 &&
                    <Time>Через {convertSeconds()} код можно запросить повторно</Time>
                }
                <NewCode disabled={second > 0}
                         onClick={getNewCode}>
                    Запросить новый код
                </NewCode>
                <Button title={"Подтвердить"}
                        isDisabled={!code}
                        margin={"0 0 12px 0"}
                        onClick={onAccept}/>
                <Button title={"Отменить"}
                        type={"transparent_red"}
                        margin={"0"}
                        onClick={() => navigate(routes.info.path)}/>
            </Form>
        </Page>
    )
};

export default AcceptPhonePage;
