export const STATE = {
    CREATED: "CREATED",
    MERGED: "MERGED",
    CLOSED: "CLOSED",
    DENIED: "DENIED",
    UNMERGED: "UNMERGED",
};

export const FROM_STATE = {
    CREATED: "CREATED",
    CONFIRMED: "CONFIRMED",
    DECLINED: "DECLINED",
};

export const TO_STATE = {
    CREATED: "CREATED",
    PROVEN: "PROVEN",
    CANCELLED: "CANCELLED",
};
