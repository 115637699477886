import React, {useState} from "react";
import styled from "styled-components";

const RadioButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 12px;
    position: relative;
    ${({margin}) => margin ? `margin: ${margin};` : ""}
    
    input[type=radio] {
        display: none;
    }
    input[type=radio]:checked + label:before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cpath d='M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18Z' fill='%23FF5145'/%3E%3Cpath d='M10 15C12.7614 15 15 12.7614 15 10C15 7.23858 12.7614 5 10 5C7.23858 5 5 7.23858 5 10C5 12.7614 7.23858 15 10 15Z' fill='%23FF5145'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
    }
    label:hover:before {
        filter: brightness(120%);
    }
`;

const Label = styled.label`
    font-weight: 600;
    font-size: 16px;
    line-height: 160%;
    color: rgba(0, 0, 0, 0.9);
    opacity: ${({disabled}) => disabled ? 0.4 : 1};
    margin-left: 36px;
   
    &:before {
        content: "";
        display: inline-block;
        width: 20px;
        height: 20px;
        position: absolute;
        left: 0;
        top: 2px;
        ${({disabled}) =>
            !disabled ?
                'background-image: url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'20\' height=\'20\' viewBox=\'0 0 20 20\' fill=\'none\'%3E%3Cpath d=\'M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18Z\' fill=\'%23FF5145\' fill-opacity=\'0.4\'/%3E%3C/svg%3E");'
            :
                `background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cpath d='M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18Z' fill='rgba(0, 0, 0, 0.9)' fill-opacity='0.4'/%3E%3C/svg%3E");`
        }
        background-repeat: no-repeat;
    }
    
    b {
        color: #FF5145;    
    }
`;

const RadioButton = ({title, id, onChange, value, margin, disabled}) => {
    return (
        <RadioButtonWrapper margin={margin}
                            disabled={disabled}>
            <input id={id}
                   onClick={() => onChange(!value)}
                   type="radio"
                   checked={value}
                   disabled={disabled}/>
            <Label htmlFor={id}
                   disabled={disabled}>
                {title}
            </Label>
        </RadioButtonWrapper>
    )
};

export default RadioButton;
