import React, {useState} from "react";
import styled from "styled-components";

const getBackgroundColor = color => {
    switch (color) {
        case "blue":
            return "#153CC9";
        default:
            return "#FF5145";
    }
};

const getHoverColor = color => {
    switch (color) {
        case "blue":
            return "#15339f";
        default:
            return "#D61C0F";
    }
};

const ButtonElement = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 12px;
    cursor: pointer;
    color: #FFFFFF;
    background: ${({bgColor}) => getBackgroundColor(bgColor)};
    border-radius: 8px;
    font-weight: 600;
    font-size: 16px;
    line-height: 120%;
    height:  ${({height}) => height || "52px"};    
    outline: none;
    border: none;
    opacity: ${({isDisabled}) => isDisabled ? 0.3 : 1};
    ${({margin}) => margin ? `margin: ${margin};` : ""}
    transition: all 300ms ease-in-out;
    pointer-events: ${({isDisabled}) => isDisabled ? 'none' : null};
    &:hover, &:active {
        background: ${({bgColor}) => getHoverColor(bgColor)};
    }
`;

const ButtonTransparentElement = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 12px;
    cursor: pointer;
    color: #FF5145;
    background: transparent;
    border-radius: 8px;
    font-weight: 600;
    font-size: 16px;
    line-height: 120%;
    height:  ${({height}) => height || "52px"}; 
    outline: none;
    border: none;
    opacity: ${({isDisabled}) => isDisabled ? 0.3 : 1};
    ${({margin}) => margin ? `margin: ${margin};` : ""}
    transition: all 500ms ease-in-out;
    pointer-events: ${({isDisabled}) => isDisabled ? 'none' : null};
    
    &:hover, &:active {
        background: rgba(255, 81, 69, 0.3);
        color: #FFFFFF;
    }
`;

const ButtonBorderElement = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 12px;
    cursor: pointer;
    color: #FF5145;
    background: white;
    border-radius: 8px;
    font-weight: 600;
    font-size: 16px;
    line-height: 120%;
    height:  ${({height}) => height || "52px"};
    outline: none;
    border: 2px solid #FF5145;
    opacity: ${({isDisabled}) => isDisabled ? 0.3 : 1};
    pointer-events: ${({isDisabled}) => isDisabled ? 'none' : null};
    ${({margin}) => margin ? `margin: ${margin};` : ""}
    transition: all 300ms ease-in-out;
    &:hover, &:active {
        color: white;
        background: #FF5145;
    }
`;

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
`;

const Button = ({title, isDisabled, onClick, margin, bgColor, type="default", height, icon = null}) => {
    switch (type) {
        case "transparent_red":
            return (
                <ButtonTransparentElement onClick={onClick}
                                          height={height}
                                          isDisabled={isDisabled}
                                          disabled={isDisabled}
                                          margin={margin}>
                    {
                        icon &&
                        <IconWrapper>
                            {icon}
                        </IconWrapper>
                    }
                    {title}
                </ButtonTransparentElement>
            );
        case "border_red":
            return (
                <ButtonBorderElement onClick={onClick}
                                     height={height}
                                     isDisabled={isDisabled}
                                     disabled={isDisabled}
                                     margin={margin}>
                    {
                        icon &&
                        <IconWrapper>
                            {icon}
                        </IconWrapper>
                    }
                    {title}
                </ButtonBorderElement>
            );
        default:
            return (
                <ButtonElement onClick={onClick}
                               height={height}
                               isDisabled={isDisabled}
                               disabled={isDisabled}
                               bgColor={bgColor}
                               margin={margin}>
                    {
                        icon &&
                        <IconWrapper>
                            {icon}
                        </IconWrapper>
                    }
                    {title}
                </ButtonElement>
            )
    }
};

export default Button;
