import React from "react";
import {useRootStore} from "../../RootStoreProvider";
import styled from "styled-components";
import {observer} from "mobx-react";

const switchColor = type => {
    switch (type) {
        case "success":
            return "#44C915";
        case "info":
            return "#4986E4";
        case "error":
            return "#F3D433";
    }
};

const ToastContainer = styled.div`
    position: fixed;
    top: 10px;
    right: 10px;
    min-width: 400px;
    max-width: 600px;
    padding-left: 6px;
    background: ${({type}) => switchColor(type)};
    border-radius: 8px;
    box-shadow: 0px 8px 24px -2px rgba(0, 0, 0, 0.06);
    z-index: 1000;
    
    @media (max-width: 435px) {
        width: calc(100% - 20px);
        min-width: unset;
    }
`;

const InnerContainer = styled.div`
    width: 100%;
    background: #fff;
    display: flex;
    align-items: center;
    padding: 12.5px 16px 12.5px 25px;
    border-radius: 0 8px 8px 0;
`;

const TextContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    
    @media (max-width: 435px) {
    margin-left: 0;
    }
`;

const IconWrapper = styled.div`
    cursor: pointer;
    min-width: 30px;
    height: 30px;
    
    @media (max-width: 435px) {
        display: none;
    }
`;

const CloseWrapper = styled.div`
    cursor: pointer;
    min-width: 32px;
    height: 32px;
`;

const Title = styled.div`
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 150%;
    color: rgba(0, 0, 0, 0.9);
    margin-bottom: 4px;
`;

const Message = styled.div`
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: rgba(0, 0, 0, 0.5);
`;

const Toast = observer(() => {
    const store = useRootStore();

    if (!store.toastStore.toast)
        return null;

    const switchIcon = () => {
        switch(store.toastStore.toast.type) {
            case "success":
                return <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30ZM14.4857 20.9603L22.819 10.9603L20.5143 9.03972L13.2325 17.7779L9.394 13.9393L7.27268 16.0607L12.2727 21.0607L13.4341 22.2221L14.4857 20.9603Z" fill="#44C915"/>
                </svg>;
            case "info":
                return <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M30 15C30 23.2843 23.2843 30 15 30C6.71573 30 0 23.2843 0 15C0 6.71573 6.71573 0 15 0C23.2843 0 30 6.71573 30 15ZM16.6667 8.33333C16.6667 9.25381 15.9205 10 15 10C14.0795 10 13.3333 9.25381 13.3333 8.33333C13.3333 7.41286 14.0795 6.66667 15 6.66667C15.9205 6.66667 16.6667 7.41286 16.6667 8.33333ZM16.5 23.3333V13.3333H13.5V23.3333H16.5Z" fill="#4986E4"/>
                </svg>;
            case "error":
                return <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M30.6667 15.6666C30.6667 7.38235 23.951 0.666626 15.6667 0.666626C7.38248 0.666626 0.666748 7.38235 0.666748 15.6666C0.666748 23.9509 7.38248 30.6666 15.6667 30.6666C23.951 30.6666 30.6667 23.9509 30.6667 15.6666ZM14.1667 17.1666L14.1667 15.6666V6.66658V5.16658L17.1667 5.16658V6.66658L17.1667 15.6666V17.1666H14.1667ZM17.1667 23.1667C17.1667 22.3382 16.4951 21.6667 15.6667 21.6667C14.8382 21.6667 14.1667 22.3382 14.1667 23.1667V24.6667C14.1667 25.4951 14.8382 26.1667 15.6667 26.1667C16.4951 26.1667 17.1667 25.4951 17.1667 24.6667V23.1667Z" fill="#F3D433"/>
                </svg>;
        }
    };

    return (
        <ToastContainer type={store.toastStore.toast.type}>
            <InnerContainer>
                <IconWrapper>
                    {switchIcon()}
                </IconWrapper>
                <TextContainer>
                    <Title>{store.toastStore.toast.title}</Title>
                    <Message>{store.toastStore.toast.message}</Message>
                </TextContainer>
                <CloseWrapper onClick={store.toastStore.closeToast}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                        <path d="M24 8L8 24" stroke="#C5C4C4" strokeWidth="3" strokeLinecap="square" strokeLinejoin="round"/>
                        <path d="M8 8L24 24" stroke="#C5C4C4" strokeWidth="3" strokeLinecap="square" strokeLinejoin="round"/>
                    </svg>
                </CloseWrapper>
            </InnerContainer>
        </ToastContainer>
    )
});

export default Toast;
