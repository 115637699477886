import React, {useEffect} from "react";
import axios from "axios";
import {BASE_URL} from "../config";
import {useNavigate} from "react-router";
import routes from "../routes";
import {useRootStore} from "../RootStoreProvider";
import get from "lodash/get";

/**
 * Страница авторизации, для определения необходимости переадрисовывания на серверную авторизацию
 */
const AuthSuccessPage = () => {
    const navigate = useNavigate();
    const store = useRootStore();

    const sendSuccess = () => {
        axios.get(`${BASE_URL}/login/oauth2/code/mosso` + window.location.search)
            .then(response => {
                navigate(routes.main.path);
            })
            .catch(error => {
                const localizedMessage = get(error.response, "data.localizedMessage", null);
                const message = get(error.response, "data.message", null);
                this.store.toastStore.showError("Внимание", localizedMessage || message);
            })
    };

    useEffect(() => {
        sendSuccess();
    }, []);

    return null;
};

export default AuthSuccessPage;
