import React from "react";

const ArrowIcon = ({onClick}) => {
    return (
        <svg onClick={onClick}
             xmlns="http://www.w3.org/2000/svg" width="24"
             height="24" viewBox="0 0 24 24" fill="none">
            <path d="M6 9L12 15L18 9" stroke="black" strokeOpacity="0.5"
                  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
};

export default ArrowIcon;
