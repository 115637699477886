import React, {useEffect, useState} from "react";
import styled from "styled-components";
import InputMask from "react-input-mask";
import EventListener from "react-event-listener";
import {useRootStore} from "../../RootStoreProvider";

const ItemWrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    margin-bottom: 24px;
`;

const ItemTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 8px;
    
    @media (max-width: 435px) {
        flex-direction: column-reverse;
        align-items: flex-start;
    }
`;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    color: rgba(0, 0, 0, 0.9);
`;

const IconWrapper = styled.div`
    display: flex;
    cursor: pointer;
    
    @media (max-width: 435px) {
        position: absolute;
        right: 0;
        z-index: 99;
    }
`;

const AcceptContainer = styled.div`
    display: flex;
    align-items: center;
    margin: 0;
    color: ${({isAccept}) => isAccept ? "#46C54B" : "#F19B35"};
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    margin-left: 12px;
    
    svg {
        margin-right: 4px;
    }
    
    @media (max-width: 435px) {
        margin-left: 0;
        margin-bottom: 2px;
    }
`;

const InputWrapper = styled(InputMask)`
    width: 100%;
    height: ${({isEdit}) => isEdit ? "52px" : "24px"};
    background: #FFFFFF;
    border: ${({isEdit}) => isEdit ? "2px solid rgba(0, 0, 0, 0.08)" : "none"};
    border-radius: 8px;
    padding: ${({isEdit}) => isEdit ? "10px 16px" : "0"};
    margin-right: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    -webkit-appearance: none;
    -webkit-user-select: text;
    user-select: text;
    -webkit-text-fill-color: rgba(0, 0, 0, 0.9);
    -webkit-opacity: 1;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    color: rgba(0, 0, 0, 0.9);
    
    &:focus-visible {
        outline: none;
    }
    
    &:focus {
        border-color: rgba(255, 81, 69, 0.2);
    }
    
    &::placeholder {
        color: rgba(0, 0, 0, 0.5);
        -webkit-text-fill-color: rgba(0, 0, 0, 0.5);
        font-size: 16px;
        line-height: 160%;
        font-weight: 400;
        font-family: 'Open Sans', sans-serif;
    }
    
    @media (max-width: 435px) {
        padding-right: 52px;
    }
`;

const EditablePhoneField = ({showAccept, accept, phone, onChange}) => {
    const store = useRootStore();
    const [isEdit, setIsEdit] = useState(false);
    const [value, setValue] = useState(phone);

    useEffect(() => {
        setValue(phone);
    }, [phone]);

    const onAccept = () => {
        if (value === "") {
            setIsEdit(false);
            setValue(phone);
        }
        else {
            if (value.replace(/\D/g, '').length === 11) {
                setIsEdit(false);
                onChange(value);
            }
            else {
                store.toastStore.showError("Внимание", "Мобильный телефон должен состоять из 11 цифр");
            }
        }
    };

    const ListeningOuterClick = e => {
        try {
            if (!e.target.closest('.phone-edit-container')) {
                setIsEdit(false);
                setValue(phone);
            }
        } catch (err) {
            return err;
        }
    };

    return (
        <>
            <EventListener target={window} onClick={ListeningOuterClick} />
            <ItemWrapper className={"phone-edit-container"}>
                <ItemTitle>
                    <span>Мобильный телефон</span>
                    {
                        showAccept &&
                        (
                            accept ?
                                <AcceptContainer isAccept={true}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                        <circle cx="9" cy="9" r="6" fill="#46CF4B"/>
                                        <path d="M7.125 9L8.51893 10.3939C8.57751 10.4525 8.67249 10.4525 8.73107 10.3939L11.625 7.5" stroke="white" strokeWidth="1.2"/>
                                    </svg>
                                    подтвержден
                                </AcceptContainer>
                                :
                                <AcceptContainer isAccept={false}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                        <circle cx="9" cy="9" r="6.75" fill="#F19B35"/>
                                        <path d="M6 9H12" stroke="white" strokeWidth="1.2"/>
                                    </svg>
                                    неподтвержден
                                </AcceptContainer>
                        )
                    }
                </ItemTitle>
                <Wrapper>
                    <InputWrapper placeholder={"+7 (999) 999-99-99"}
                                  value={isEdit ? value : (value || "-")}
                                  isEdit={isEdit}
                                  disabled={!isEdit}
                                  mask={isEdit || value ? "+7 (999) 999-99-99" : ""}
                                  onChange={e => setValue(e.target.value)}/>
                    {
                        isEdit ?
                            <IconWrapper onClick={onAccept}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52" fill="none">
                                    <rect width="52" height="52" rx="8" fill="#FF5145"/>
                                    <path d="M19 28L22.2331 30.4248C22.6618 30.7463 23.2677 30.6728 23.607 30.2581L32 20"
                                          stroke="white" strokeWidth="3" strokeLinecap="round"/>
                                </svg>
                            </IconWrapper>
                        :
                            <IconWrapper onClick={() => setIsEdit(true)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                    <rect width="40" height="40" rx="8" fill="#FF5145"/>
                                    <path d="M24.9997 18.3333L21.6664 14.9999M12.083 27.9166L14.9033 27.6032C15.2479 27.5649 15.4202 27.5458 15.5812 27.4937C15.7241 27.4474 15.86 27.3821 15.9854 27.2994C16.1267 27.2062 16.2493 27.0836 16.4944 26.8385L27.4997 15.8333C28.4202 14.9128 28.4202 13.4204 27.4997 12.4999C26.5792 11.5795 25.0868 11.5795 24.1664 12.4999L13.1611 23.5052C12.916 23.7503 12.7934 23.8729 12.7002 24.0142C12.6175 24.1396 12.5522 24.2755 12.5059 24.4184C12.4538 24.5794 12.4347 24.7517 12.3964 25.0963L12.083 27.9166Z"
                                          stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </IconWrapper>
                    }
                </Wrapper>
            </ItemWrapper>
        </>
    )
};

export default EditablePhoneField;
