import React, {useRef, useState} from "react";
import styled from "styled-components";
import Button from "../Elements/Button";
import {useRootStore} from "../RootStoreProvider";
import EmailInput from "../Elements/EmailInput";
import PhoneInput from "../Elements/PhoneInput";
import Data from "./data";
import routes from "../routes";
import {observer} from "mobx-react";

const Title = styled.div`
    margin: 0;
    color: rgba(0, 0, 0, 0.9);
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    margin-bottom: 24px;
    
    @media (max-width: 435px) {
        font-size: 22px;
    }
`;

const SuccessSubtitle = styled.p`
    margin: 0 0 24px 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    color: rgba(0, 0, 0, 0.5);
    
    b {
        color: rgba(0, 0, 0, 0.9);
    }
`;

const Subtitle = styled.div`
    margin: 0 0 24px 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    color: rgba(0, 0, 0, 0.9);
`;

const InputLoginWrapper = styled.div`
    position: relative;
    margin-bottom: 24px;
    width: 100%;
    
    input {
        padding-right: 70px;
    }
`;

const ControlIconsWrapper = styled.div`
    position: absolute;
    top: 10px;
    right: 16px;
    width: 44px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const IconInInput = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ArrowIconWrapper = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
`;

const SelectUsernameTypeWrapper = styled.div`
    position: absolute;
    width: 100%;
    left: 0;
    top: 56px;
    z-index: 99;
    border: 2px solid rgba(0, 0, 0, 0.08);
    filter: drop-shadow(0px 5px 6px rgba(194, 201, 206, 0.35));
    border-radius: 8px;
    background-color: white;
`;

const SelectItem = styled.div`
    width: 100%;
    height: 52px;
    display: flex;
    align-items: center;
    position: relative;
    padding: 0 16px;
    cursor: pointer;
    
    &:hover {
        background: #FFF2F1;
    }
`;

const SelectItemText = styled.div`
    margin-left: 16px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    color: rgba(0, 0, 0, 0.9);
`;

const CheckIconWrapper = styled.div`
    position: absolute;
    right: 16px;
`;

const ButtonCancelWrapper = styled.div`
    width: 436px;
    
    @media (max-width: 435px) {
        width: calc(100% - 32px);
    }
`;

const Page = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 100px;
    
    @media (max-width: 435px) {
        margin-top: 0;
        flex-grow: 1;
    }
`;

const Form = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 40px 30px;
    width: 436px;
    background: #FFFFFF;
    box-shadow: 0 4px 30px rgba(194, 201, 206, 0.35);
    border-radius: 24px;
    margin-bottom: 20px;
    position: relative;
    z-index: 999;
    
    @media (max-width: 435px) {
        margin-top: 0;
        width: 100%;
        padding: 24px 16px;
        border-radius: 0 0 24px 24px;
    }
`;

/**
 * Страница восстановления пароля
 */
const RecoveryPasswordPage = observer(() => {
    const store = useRootStore();
    const data = useRef(new Data(store.userStore, store.toastStore));
    const [isOpenSelectPopup, setIsOpenSelectPopup] = useState(false);

    /**
     * Изменение значения поля, по которому будет восстановление пароля
     */
    const onChangeType = type => {
        data.current.onChangeType(type);
        setIsOpenSelectPopup(false);
    };

    return (
        <Page>
            <Form>
                <Title>Восстановление пароля</Title>
                {
                    data.current.success ?
                        <SuccessSubtitle>Для восстановления пароля перейдите по ссылке, отправленной на <b>{data.current.successResult}</b></SuccessSubtitle>
                    :
                        <Subtitle>Выберите способ восстановления пароля:</Subtitle>
                }
                {
                    !data.current.success ?
                        <>
                            <InputLoginWrapper>
                                {
                                    data.current.type === "email" ?
                                        <EmailInput placeholder="Электронная почта"
                                                    onChange={val => data.current.email = val}
                                                    onChangeError={val => data.current.emailError = val}
                                                    value={data.current.email}/>
                                        :
                                        <PhoneInput placeholder="+7 (999) 999-99-99"
                                                    onChange={val => data.current.phone = val}
                                                    value={data.current.phone}/>
                                }
                                <ControlIconsWrapper>
                                    {
                                        data.current.type === "email" ?
                                            <IconInInput>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24"
                                                     fill="none">
                                                    <path
                                                        d="M2.5 7L10.6649 12.7154C11.3261 13.1783 11.6567 13.4097 12.0163 13.4993C12.3339 13.5785 12.6661 13.5785 12.9837 13.4993C13.3433 13.4097 13.6739 13.1783 14.3351 12.7154L22.5 7M7.3 20H17.7C19.3802 20 20.2202 20 20.862 19.673C21.4265 19.3854 21.8854 18.9265 22.173 18.362C22.5 17.7202 22.5 16.8802 22.5 15.2V8.8C22.5 7.11984 22.5 6.27976 22.173 5.63803C21.8854 5.07354 21.4265 4.6146 20.862 4.32698C20.2202 4 19.3802 4 17.7 4H7.3C5.61984 4 4.77976 4 4.13803 4.32698C3.57354 4.6146 3.1146 5.07354 2.82698 5.63803C2.5 6.27976 2.5 7.11984 2.5 8.8V15.2C2.5 16.8802 2.5 17.7202 2.82698 18.362C3.1146 18.9265 3.57354 19.3854 4.13803 19.673C4.77976 20 5.61984 20 7.3 20Z"
                                                        stroke="#FF5145" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                </svg>
                                            </IconInInput>
                                            :
                                            <IconInInput>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                                     fill="none">
                                                    <path
                                                        d="M8.38028 8.85335C9.07627 10.303 10.0251 11.6616 11.2266 12.8632C12.4282 14.0648 13.7869 15.0136 15.2365 15.7096C15.3612 15.7694 15.4235 15.7994 15.5024 15.8224C15.7828 15.9041 16.127 15.8454 16.3644 15.6754C16.4313 15.6275 16.4884 15.5704 16.6027 15.4561C16.9523 15.1064 17.1271 14.9316 17.3029 14.8174C17.9658 14.3864 18.8204 14.3864 19.4833 14.8174C19.6591 14.9316 19.8339 15.1064 20.1835 15.4561L20.3783 15.6509C20.9098 16.1824 21.1755 16.4481 21.3198 16.7335C21.6069 17.301 21.6069 17.9713 21.3198 18.5389C21.1755 18.8242 20.9098 19.09 20.3783 19.6214L20.2207 19.779C19.6911 20.3087 19.4263 20.5735 19.0662 20.7757C18.6667 21.0001 18.0462 21.1615 17.588 21.1601C17.1751 21.1589 16.8928 21.0788 16.3284 20.9186C13.295 20.0576 10.4326 18.4332 8.04466 16.0452C5.65668 13.6572 4.03221 10.7948 3.17124 7.76144C3.01103 7.19699 2.93092 6.91477 2.9297 6.50182C2.92833 6.0436 3.08969 5.42311 3.31411 5.0236C3.51636 4.66357 3.78117 4.39876 4.3108 3.86913L4.46843 3.7115C4.99987 3.18006 5.2656 2.91433 5.55098 2.76999C6.11854 2.48292 6.7888 2.48292 7.35636 2.76999C7.64174 2.91433 7.90747 3.18006 8.43891 3.7115L8.63378 3.90637C8.98338 4.25597 9.15819 4.43078 9.27247 4.60655C9.70347 5.26945 9.70347 6.12403 9.27247 6.78692C9.15819 6.96269 8.98338 7.1375 8.63378 7.4871C8.51947 7.60142 8.46231 7.65857 8.41447 7.72538C8.24446 7.96281 8.18576 8.30707 8.26748 8.58743C8.29048 8.66632 8.32041 8.72866 8.38028 8.85335Z"
                                                        stroke="#FF5145" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                </svg>
                                            </IconInInput>
                                    }
                                    <ArrowIconWrapper onClick={() => setIsOpenSelectPopup(!isOpenSelectPopup)}
                                                      style={{transform: `rotate(${isOpenSelectPopup ? "180deg" : "0"})`}}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16"
                                             fill="none">
                                            <path d="M4.5 6L8.5 10L12.5 6" stroke="black" strokeOpacity="0.9" strokeWidth="2"
                                                  strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </ArrowIconWrapper>
                                </ControlIconsWrapper>
                                {
                                    isOpenSelectPopup &&
                                    <SelectUsernameTypeWrapper>
                                        <SelectItem onClick={() => onChangeType("email")}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24"
                                                 fill="none">
                                                <path
                                                    d="M2.5 7L10.6649 12.7154C11.3261 13.1783 11.6567 13.4097 12.0163 13.4993C12.3339 13.5785 12.6661 13.5785 12.9837 13.4993C13.3433 13.4097 13.6739 13.1783 14.3351 12.7154L22.5 7M7.3 20H17.7C19.3802 20 20.2202 20 20.862 19.673C21.4265 19.3854 21.8854 18.9265 22.173 18.362C22.5 17.7202 22.5 16.8802 22.5 15.2V8.8C22.5 7.11984 22.5 6.27976 22.173 5.63803C21.8854 5.07354 21.4265 4.6146 20.862 4.32698C20.2202 4 19.3802 4 17.7 4H7.3C5.61984 4 4.77976 4 4.13803 4.32698C3.57354 4.6146 3.1146 5.07354 2.82698 5.63803C2.5 6.27976 2.5 7.11984 2.5 8.8V15.2C2.5 16.8802 2.5 17.7202 2.82698 18.362C3.1146 18.9265 3.57354 19.3854 4.13803 19.673C4.77976 20 5.61984 20 7.3 20Z"
                                                    stroke="#FF5145" strokeWidth="1.5" strokeLinecap="round"
                                                    strokeLinejoin="round"/>
                                            </svg>
                                            <SelectItemText>Электронная почта</SelectItemText>
                                            {
                                                data.current.type === "email" &&
                                                <CheckIconWrapper>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                                         fill="none">
                                                        <path d="M20 6L9 17L4 12" stroke="#FF5145" strokeWidth="2"
                                                              strokeLinecap="round" strokeLinejoin="round"/>
                                                    </svg>
                                                </CheckIconWrapper>
                                            }
                                        </SelectItem>
                                        <SelectItem onClick={() => onChangeType("phone")}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                                 fill="none">
                                                <path
                                                    d="M8.38028 8.85335C9.07627 10.303 10.0251 11.6616 11.2266 12.8632C12.4282 14.0648 13.7869 15.0136 15.2365 15.7096C15.3612 15.7694 15.4235 15.7994 15.5024 15.8224C15.7828 15.9041 16.127 15.8454 16.3644 15.6754C16.4313 15.6275 16.4884 15.5704 16.6027 15.4561C16.9523 15.1064 17.1271 14.9316 17.3029 14.8174C17.9658 14.3864 18.8204 14.3864 19.4833 14.8174C19.6591 14.9316 19.8339 15.1064 20.1835 15.4561L20.3783 15.6509C20.9098 16.1824 21.1755 16.4481 21.3198 16.7335C21.6069 17.301 21.6069 17.9713 21.3198 18.5389C21.1755 18.8242 20.9098 19.09 20.3783 19.6214L20.2207 19.779C19.6911 20.3087 19.4263 20.5735 19.0662 20.7757C18.6667 21.0001 18.0462 21.1615 17.588 21.1601C17.1751 21.1589 16.8928 21.0788 16.3284 20.9186C13.295 20.0576 10.4326 18.4332 8.04466 16.0452C5.65668 13.6572 4.03221 10.7948 3.17124 7.76144C3.01103 7.19699 2.93092 6.91477 2.9297 6.50182C2.92833 6.0436 3.08969 5.42311 3.31411 5.0236C3.51636 4.66357 3.78117 4.39876 4.3108 3.86913L4.46843 3.7115C4.99987 3.18006 5.2656 2.91433 5.55098 2.76999C6.11854 2.48292 6.7888 2.48292 7.35636 2.76999C7.64174 2.91433 7.90747 3.18006 8.43891 3.7115L8.63378 3.90637C8.98338 4.25597 9.15819 4.43078 9.27247 4.60655C9.70347 5.26945 9.70347 6.12403 9.27247 6.78692C9.15819 6.96269 8.98338 7.1375 8.63378 7.4871C8.51947 7.60142 8.46231 7.65857 8.41447 7.72538C8.24446 7.96281 8.18576 8.30707 8.26748 8.58743C8.29048 8.66632 8.32041 8.72866 8.38028 8.85335Z"
                                                    stroke="#FF5145" strokeWidth="1.5" strokeLinecap="round"
                                                    strokeLinejoin="round"/>
                                            </svg>
                                            <SelectItemText>Телефон</SelectItemText>
                                            {
                                                data.current.type === "phone" &&
                                                <CheckIconWrapper>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                                         fill="none">
                                                        <path d="M20 6L9 17L4 12" stroke="#FF5145" strokeWidth="2"
                                                              strokeLinecap="round" strokeLinejoin="round"/>
                                                    </svg>
                                                </CheckIconWrapper>
                                            }
                                        </SelectItem>
                                    </SelectUsernameTypeWrapper>
                                }
                            </InputLoginWrapper>
                            <Button title={"Восстановить"}
                                    isDisabled={data.current.disabledForm}
                                    margin={"0 0 12px 0"}
                                    onClick={data.current.sendRecoveryPassword}/>
                        </>
                    :
                        <Button title={"Закрыть"}
                                margin={"0"}
                                onClick={data.current.onCancel}/>
                }
            </Form>
            {
                !data.current.success &&
                <ButtonCancelWrapper>
                    <Button title={"Отменить"}
                            type={"transparent_red"}
                            margin={"0"}
                            onClick={data.current.onCancel}/>
                </ButtonCancelWrapper>
            }
        </Page>
    )
});

export default RecoveryPasswordPage;
