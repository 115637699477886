import React, {createContext, useContext} from "react";
import RootStore from "./store/RootStore";

let store;
const StoreContext = createContext(undefined);

export default function RootStoreProvider({ children }) {
    const root = store ?? new RootStore();

    return <StoreContext.Provider value={root}>{children}</StoreContext.Provider>
}

// create the hook
export function useRootStore() {
    const context = useContext(StoreContext);
    if (context === undefined) {
        throw new Error("useRootStore must be used within RootStoreProvider")
    }

    return context
}
