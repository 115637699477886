import React, {useEffect, useState} from "react";
import styled from "styled-components";
import Input from "../Elements/Input";
import Button from "../Elements/Button";
import {useRootStore} from "../RootStoreProvider";
import {useLocation, useNavigate} from "react-router";
import routes from "../routes";

const Title = styled.div`
    margin: 0;
    color: rgba(0, 0, 0, 0.9);
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    margin-bottom: 24px;
    
    @media (max-width: 435px) {
        font-size: 22px;
    }
`;

const Subtitle = styled.div`
    margin: 0;
    color: rgba(0, 0, 0, 0.9);
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    text-align: start;
    width: 100%;
    margin-bottom: 24px;
`;

const GenerateText = styled.div`
    font-weight: 600;
    font-size: 16px;
    line-height: 160%;
    color: #FF5145;
    margin-top: 4px;
    margin-bottom: 12px;
    cursor: pointer;
`;

const SuccessText = styled.div`
    margin: 0;
    color: rgba(0, 0, 0, 0.9);
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    text-align: start;
    margin-top: 24px;
`;

const ErrorWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #D61C0F;
    margin-bottom: 12px;
`;

const Dot = styled.div`
    width: 6px;
    height: 6px;
    background: #D61C0F;
    margin-right: 8px;
    border-radius: 50px;
`;

const Page = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 100px;
    
    @media (max-width: 435px) {
        margin-top: 0;
        flex-grow: 1;
    }
`;

const Form = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 40px 30px;
    width: 436px;
    background: #FFFFFF;
    box-shadow: 0 4px 30px rgba(194, 201, 206, 0.35);
    border-radius: 24px;
    margin-bottom: 20px;
    position: relative;
    z-index: 999;
    
    @media (max-width: 435px) {
        margin-top: 0;
        width: 100%;
        padding: 24px 16px;
        border-radius: 0 0 24px 24px;
    }
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 32px 0 32px 0;
`;

/**
 * Страница успешного восттановления пароля
 */
const RecoveryPasswordSuccessPage = () => {
    const store = useRootStore();
    const [password, setPassword] = useState("");
    const [passwordRepeat, setPasswordRepeat] = useState("");
    const [successRecovery, setSuccessRecovery] = useState(false);
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const token = params.get("token");

    const isPasswordNotValid = password && password.length < 6;

    const disabledForm = !password || !passwordRepeat || password !== passwordRepeat || isPasswordNotValid;

    /**
     * Обработчик кнопки "Принять"
     */
    const onClickSave = async () => {
        const result = await store.userStore.recoveryPassword(password, token);
        if (result) {
            setSuccessRecovery(true);
        }
    };

    /**
     * Блокировка ввода пробелов в пароль
     */
    const onKeyPress = e => {
        if (e.key === ' ') {
            e.preventDefault();
        }
    };

    /**
     * Генерация пароля АПИ
     */
    const generatePass = async () => {
        const password = await store.userStore.generatePassword();
        if (!password) {
            return;
        }

        setPassword(password);
        setPasswordRepeat(password);
    };

    return (
        <Page>
            <Form>
                <Title>Восстановление пароля</Title>
                {
                    !successRecovery ?
                        <>
                            <Subtitle>Придумайте новый пароль:</Subtitle>
                            <Input placeholder="Введите новый пароль"
                                   onChange={setPassword}
                                   type={"password"}
                                   iconColor={"#ff5145"}
                                   onKeyPress={onKeyPress}
                                   margin={isPasswordNotValid ? "12px" : "0"}
                                   isError={isPasswordNotValid}
                                   value={password}/>
                            {
                                isPasswordNotValid &&
                                <ErrorWrapper>
                                    <Dot/>Пароль должен содержать минимум 6 символов
                                </ErrorWrapper>
                            }
                            <GenerateText onClick={generatePass}>
                                Сгенерировать сложный пароль
                            </GenerateText>
                            <Input placeholder="Подтвердите пароль"
                                   onChange={setPasswordRepeat}
                                   margin={password !== passwordRepeat ? "12px" : "24px"}
                                   type={"password"}
                                   isRepeatPassword
                                   iconColor={"#ff5145"}
                                   onKeyPress={onKeyPress}
                                   isError={password !== passwordRepeat}
                                   value={passwordRepeat}/>
                            {
                                password !== passwordRepeat &&
                                <ErrorWrapper>
                                    <Dot/>Пароли не совпадают
                                </ErrorWrapper>
                            }
                            <Button title={"Сохранить"}
                                    isDisabled={disabledForm}
                                    margin={"0 0 12px 0"}
                                    onClick={onClickSave}/>
                        </>
                    :
                        <>
                            <Container>
                                <svg xmlns="http://www.w3.org/2000/svg" width="81" height="80" viewBox="0 0 81 80" fill="none">
                                    <path d="M30.4998 40.0001L37.1665 46.6667L52.1665 31.6667M24.9457 12.7291C27.6252 12.5152 30.1689 11.4616 32.2147 9.71815C36.9889 5.64962 44.0108 5.64962 48.7849 9.71815C50.8308 11.4616 53.3745 12.5152 56.0539 12.7291C62.3067 13.228 67.2719 18.1932 67.7709 24.446C67.9847 27.1254 69.0383 29.6691 70.7818 31.715C74.8503 36.4892 74.8503 43.511 70.7818 48.2852C69.0383 50.331 67.9847 52.8747 67.7709 55.5542C67.2719 61.8069 62.3067 66.7721 56.0539 67.2711C53.3745 67.4849 50.8308 68.5386 48.7849 70.282C44.0108 74.3505 36.9889 74.3505 32.2147 70.282C30.1689 68.5386 27.6252 67.4849 24.9457 67.2711C18.693 66.7721 13.7278 61.8069 13.2288 55.5542C13.015 52.8747 11.9614 50.331 10.2179 48.2852C6.14937 43.511 6.14937 36.4892 10.2179 31.715C11.9614 29.6691 13.015 27.1254 13.2288 24.446C13.7278 18.1932 18.693 13.228 24.9457 12.7291Z" stroke="#4EC870" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                <SuccessText>Пароль успешно изменен</SuccessText>
                                <SuccessText>Можете закрыть эту страницу</SuccessText>
                            </Container>
                        </>
                }
            </Form>
        </Page>
    )
};

export default RecoveryPasswordSuccessPage;
