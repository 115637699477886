import React, {useState} from "react";
import styled from "styled-components";
import Input from "../Elements/Input";
import Button from "../Elements/Button";
import {useRootStore} from "../RootStoreProvider";
import {useNavigate} from "react-router";
import routes from "../routes";

const Page = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    @media (max-width: 435px) {
      justify-content: flex-start;
    }
`;

const Title = styled.div`
    margin: 0;
    color: rgba(0, 0, 0, 0.9);
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    margin-bottom: 40px;
    
    @media (max-width: 435px) {
        font-size: 22px;
        margin-bottom: 24px;
    }
`;

const Subtitle = styled.div`
    margin: 0;
    color: rgba(0, 0, 0, 0.9);
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    text-align: center;
    width: 100%;
    margin-bottom: 40px;
    
    @media (max-width: 435px) {
        margin-bottom: 24px;
    }
`;

const GenerateText = styled.div`
    font-weight: 600;
    font-size: 16px;
    line-height: 160%;
    color: #FF5145;
    margin-top: 4px;
    margin-bottom: 12px;
    cursor: pointer;
`;

const ErrorWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #D61C0F;
    margin-bottom: 12px;
`;

const Dot = styled.div`
    width: 6px;
    height: 6px;
    background: #D61C0F;
    margin-right: 8px;
    border-radius: 50px;
`;

const Form = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 40px 30px;
    width: 436px;
    background: #FFFFFF;
    box-shadow: 0 4px 30px rgba(194, 201, 206, 0.35);
    border-radius: 24px;
    margin-top: 100px;
    margin-bottom: 20px;
    position: relative;
    z-index: 999;
    
    @media (max-width: 435px) {
        margin-top: 0;
        width: 100%;
        padding: 24px 16px;
        border-radius: 0 0 24px 24px;
    }
`;

/**
 * Страница смены пароля
 */
const ChangePasswordPage = () => {
    const store = useRootStore();
    const navigate = useNavigate();
    const [passwordOld, setPasswordOld] = useState("");
    const [password, setPassword] = useState("");
    const [passwordRepeat, setPasswordRepeat] = useState("");


    const isPasswordNotValid = password && password.length < 6;

    const disabledForm = !passwordOld || !password || !passwordRepeat || password !== passwordRepeat || isPasswordNotValid;

    /**
     * Генерация новго пароля
     */
    const generatePass = async () => {
        const password = await store.userStore.generatePassword();
        if (!password) {
            return;
        }

        setPassword(password);
        setPasswordRepeat(password);
    };

    /**
     * Запрет ввода проблема
     */
    const onKeyPress = e => {
        if (e.key === ' ') {
            e.preventDefault();
        }
    };

    return (
        <Page>
            <Form>
                <Title>Информационные системы Московской области</Title>
                <Subtitle>Изменение пароля</Subtitle>
                <Input placeholder="Введите текущий пароль"
                       onChange={setPasswordOld}
                       type={"password"}
                       margin={"24px"}
                       onKeyPress={onKeyPress}
                       value={passwordOld}/>
                <Input placeholder="Введите новый пароль"
                       onChange={setPassword}
                       type={"password"}
                       margin={isPasswordNotValid ? "12px" : "0"}
                       isError={isPasswordNotValid}
                       onKeyPress={onKeyPress}
                       value={password}/>
                {
                    isPasswordNotValid &&
                    <ErrorWrapper>
                        <Dot/>Пароль должен содержать минимум 6 символов
                    </ErrorWrapper>
                }
                <GenerateText onClick={generatePass}>
                    Сгенерировать сложный пароль
                </GenerateText>
                <Input placeholder="Подтвердите новый пароль"
                       onChange={setPasswordRepeat}
                       type={"password"}
                       isRepeatPassword
                       onKeyPress={onKeyPress}
                       isError={password !== passwordRepeat}
                       value={passwordRepeat}/>
                {
                    password !== passwordRepeat &&
                    <ErrorWrapper>
                        <Dot/>Пароли не совпадают
                    </ErrorWrapper>
                }
                <Button title={"Сохранить"}
                        isDisabled={disabledForm}
                        margin={"0 0 12px 0"}
                        onClick={() => store.userStore.changePassword(navigate, passwordOld, password)}/>
                <Button title={"Отменить"}
                        type={"transparent_red"}
                        margin={"0"}
                        onClick={() => navigate(-1)}/>
            </Form>
        </Page>
    )
};

export default ChangePasswordPage;
