import React, {useState} from "react";
import styled from "styled-components";
import Button from "../Elements/Button";
import {useNavigate} from "react-router";
import routes from "../routes";
import {useRootStore} from "../RootStoreProvider";
import {FROM_STATE, STATE, TO_STATE} from "./const";
import StatusIconSuccess from "../Elements/Icons/StatusIconSuccess";
import StatusIconError from "../Elements/Icons/StatusIconError";
import ArrowIcon from "../Elements/Icons/ArrowIcon";
import {observer} from "mobx-react";

const Page = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    @media (max-width: 435px) {
      justify-content: flex-start;
    }
`;

const Title = styled.p`
    margin: 0;
    color: rgba(0, 0, 0, 0.9);
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    margin-bottom: 40px;
    
    @media (max-width: 435px) {
        font-size: 22px;
        margin-bottom: 24px;
    }
`;

const Subtitle = styled.p`
    margin: 0;
    color: rgba(0, 0, 0, 0.9);
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    margin-bottom: 40px;
    text-align: center;

    @media (max-width: 435px) {
        margin-bottom: 24px;
    }
    
    b {
        font-weight: 600;
        color: #FF5145;
    }
`;

const Form = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 40px 30px;
    width: 436px;
    background: #FFFFFF;
    box-shadow: 0 4px 30px rgba(194, 201, 206, 0.35);
    border-radius: 24px;
    margin-top: 100px;
    margin-bottom: 24px;
    position: relative;
    z-index: 999;
    
    @media (max-width: 435px) {
        margin-top: 0;
        width: 100%;
        padding: 24px 16px;
        border-radius: 0 0 24px 24px;
    }
`;

const EsiaInfo = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    margin-bottom: 16px;
    color: rgba(0, 0, 0, 0.9);
`;

const BlockTitle = styled.div`
    font-weight: 600;
    font-size: 16px;
    line-height: 160%;
    color: rgba(0, 0, 0, 0.9);
    margin-bottom: 16px;
    margin-top: 24px;
`;

const BlockWrapper = styled.div`
    padding: 16px;
    margin-bottom: 16px;
    gap: 16px;
    width: 100%;    
    background: #FAFAFA;
    border-radius: 12px;
    border: 1px solid #E9E9E9;
`;

const BlockSubtitle = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: rgba(0, 0, 0, 0.9);
    margin-bottom: 16px;
    
    b {
        color: rgba(255, 81, 69, 1);
    }
`;

const BlockButtonsContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const StatusWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 6px;
`;

const StatusText = styled.div`
    margin-left: 4px;
    color: ${({color}) => color};
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
`;

const HistoryTitleWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    font-size: 16px;
    line-height: 160%;
    color: rgba(0, 0, 0, 0.9);
    margin-bottom: 16px;
    margin-top: 24px;
    cursor: pointer; 
    
    svg {
        transform: ${({isOpened}) => `rotate(${isOpened ? "180deg" : "0"});`}
    }
`;

/**
 * СТраница списка заявок на слияний с их историей
 */
const RequestsPage = observer(() => {
    const store = useRootStore();
    const navigate = useNavigate();
    const [historyIsOpen, setHistoryIsOpen] = useState(false);

    /**
     * Определение статуса
     */
    const getStatus = item => {
        switch (item.state) {
            case STATE.CREATED:
                if (item.fromState === FROM_STATE.CREATED && item.toState === TO_STATE.CREATED) {
                    return <StatusWrapper>
                        <StatusIconSuccess fill={"#F19B35"}/>
                        <StatusText color={"#F19B35"}>создан</StatusText>
                    </StatusWrapper>;
                }
                if (item.fromState !== FROM_STATE.CREATED) {
                    if (item.fromState === FROM_STATE.CONFIRMED) {
                        return <StatusWrapper>
                            <StatusIconSuccess fill={"#46C54B"}/>
                            <StatusText color={"#46C54B"}>принят</StatusText>
                        </StatusWrapper>;
                    }
                    if (item.fromState === FROM_STATE.DECLINED) {
                        return <StatusWrapper>
                            <StatusIconError fill={"#D61C0F"}/>
                            <StatusText color={"#D61C0F"}>отклонен</StatusText>
                        </StatusWrapper>;
                    }
                }
                if (item.toState !== TO_STATE.CREATED) {
                    if (item.toState === TO_STATE.PROVEN) {
                        return <StatusWrapper>
                            <StatusIconSuccess fill={"#F19B35"}/>
                            <StatusText color={"#F19B35"}>подтвержден</StatusText>
                        </StatusWrapper>;
                    }
                    if (item.toState === TO_STATE.CANCELLED) {
                        return <StatusWrapper>
                            <StatusIconError fill={"#D61C0F"}/>
                            <StatusText color={"#D61C0F"}>отменен пользователем</StatusText>
                        </StatusWrapper>;
                    }
                }
                break;
            case STATE.MERGED:
                return <StatusWrapper>
                    <StatusIconSuccess fill={"#46C54B"}/>
                    <StatusText color={"#46C54B"}>объединен</StatusText>
                    </StatusWrapper>;
            case STATE.UNMERGED:
                return <StatusWrapper>
                        <StatusIconSuccess fill={"#D61C0F"}/>
                        <StatusText color={"#D61C0F"}>аккаунт отсоединен</StatusText>
                    </StatusWrapper>;
            case STATE.CLOSED:
                return <StatusWrapper>
                    <StatusIconError fill={"#D61C0F"}/>
                    <StatusText color={"#D61C0F"}>отменен администратором</StatusText>
                </StatusWrapper>;
            case STATE.DENIED:
                return <StatusWrapper>
                    <StatusIconError fill={"#D61C0F"}/>
                    <StatusText color={"#D61C0F"}>заблокирован</StatusText>
                </StatusWrapper>;
        }
    };

    /**
     * Переадресация на отклонение слияния
     */
    const cancelRequest = (item, type) => {
        navigate(routes.cancelRequest.path, {state : {request_id: item.id, username: item.username, type}})
    };

    return (
        <Page>
            <Form>
                <Title>Информационные системы Московской области</Title>
                <Subtitle>Объдинение учетных записей</Subtitle>
                {
                    store.userStore.esiaId &&
                    <Button title={"Создать запрос на объединение"}
                            margin={"0 0 12px 0"}
                            height={"52px"}
                            onClick={() => navigate(routes.createRequest.path)}/>
                }
                <EsiaInfo>Слиянию подлежат только верифицированные через ЕСИА аккаунты с неверифицированными аккаунтами</EsiaInfo>
                {
                    store.userStore.esiaId &&
                    <>
                        <BlockTitle>Исходящие запросы: {store.userStore.outgoingMergeRequests.length === 0 && "отсутствуют"}</BlockTitle>
                        {
                            store.userStore.outgoingMergeRequests.length > 0 &&
                            store.userStore.outgoingMergeRequests.map((item, i) => {
                                return (
                                    <BlockWrapper key={i}>
                                        {getStatus(item)}
                                        <BlockSubtitle>Запрос на объединение с <b>{item.username}</b></BlockSubtitle>
                                        {
                                            item.state === STATE.CREATED &&
                                            item.toState === TO_STATE.CREATED &&
                                            item.fromState === FROM_STATE.CREATED &&
                                            <Button title={"Отменить"}
                                                    type={"border_red"}
                                                    margin={"0"}
                                                    height={"40px"}
                                                    onClick={() => cancelRequest(item, "cancel")}/>
                                        }
                                    </BlockWrapper>
                                )
                            })
                        }
                    </>
                }
                {
                    !store.userStore.esiaId &&
                    <>
                        <BlockTitle>Входящие запросы: {store.userStore.incomingMergeRequests.length === 0 && "отсутствуют"}</BlockTitle>
                        {
                            store.userStore.incomingMergeRequests.length > 0 &&
                            store.userStore.incomingMergeRequests.map((item, i) => {
                                return (
                                    <BlockWrapper key={i}>
                                        {getStatus(item)}
                                        <BlockSubtitle>Запрос на объединение с <b>{item.username}</b></BlockSubtitle>
                                        {
                                            item.state === STATE.CREATED &&
                                            item.toState === TO_STATE.CREATED &&
                                            item.fromState === FROM_STATE.CREATED &&
                                            <BlockButtonsContainer>
                                                <Button title={"Отказать"}
                                                        type={"border_red"}
                                                        margin={"0 2px 0 0"}
                                                        height={"40px"}
                                                        onClick={() => cancelRequest(item, "decide")}/>
                                                <Button title={"Принять"}
                                                        margin={"0 0 0 2px"}
                                                        height={"40px"}
                                                        onClick={() => store.userStore.confirmIncomingRequest(item.id)}/>
                                            </BlockButtonsContainer>
                                        }
                                    </BlockWrapper>
                                )
                            })
                        }
                    </>
                }
                {
                    store.userStore.historyRequests.length > 0 &&
                    <>
                        <HistoryTitleWrapper isOpened={historyIsOpen}
                                             onClick={() => setHistoryIsOpen(!historyIsOpen)}>
                            История запросов ({store.userStore.historyRequests.length})
                            <ArrowIcon />
                        </HistoryTitleWrapper>
                        {
                            historyIsOpen &&
                            store.userStore.historyRequests.map((item, i) => {
                                return (
                                    <BlockWrapper key={i}>
                                        {getStatus(item)}
                                        <BlockSubtitle>Запрос на объединение с <b>{item.username}</b></BlockSubtitle>
                                    </BlockWrapper>
                                )
                            })
                        }
                    </>
                }
                <Button title={"Назад"}
                        type={"transparent_red"}
                        margin={"24px 0 0 0"}
                        onClick={() => navigate(routes.main.path)}/>
            </Form>
        </Page>
    )
});

export default RequestsPage;
