import React, {useEffect, useState} from "react";
import useInterval from "../../CustomHooks/useInterval";


const TextTimer = props => {
    const [seconds, setSeconds] = useState(props.seconds);

    useEffect(() => {
        setSeconds(props.seconds);
    }, [props.seconds]);


    useInterval(() => {
        const newSeconds = seconds - 1;
        setSeconds(newSeconds);
        if (newSeconds <= 0) {
            props.onCancel();
        }
    }, 1000);

    const convertSeconds = () => {
        const min = Math.floor(seconds / 60);
        const sec = seconds - (min*60);
        return `${min}:${sec.toString().length === 1 ? "0" : ""}${sec}`;
    };

    return convertSeconds();
};

export default TextTimer;
