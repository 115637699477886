import {makeAutoObservable} from "mobx";
import {validateEmail, validatePhone} from "../functions";
import routes from "../routes";
import axios from "axios";
import {BASE_URL, LOCALSTORAGE_KEYS} from "../config";
import get from "lodash/get";

export default class Data {
    type = "email";
    email = "";
    emailError = true;
    phone = "";
    success = false;
    userStore;
    toastStore;

    constructor(userStore, toastStore) {
        this.userStore = userStore;
        this.toastStore = toastStore;
        makeAutoObservable(this);
    }

    /**
     * Изменение типа
     */
    onChangeType = type => {
        this.type = type;
        this.email = "";
        this.phone = "";
    };

    /**
     * Флаг блокировки формы
     */
    get disabledForm() {
        if (this.type === "email") {
            if (this.email) {
                return this.emailError;
            }
            return true;
        }
        else {
            if (this.phone) {
                console.log("this.phone", this.phone.replace(/\D/g, ''), this.phone.replace(/\D/g, '').length);
                return this.phone.replace(/\D/g, '').length !== 11;
            }
            return false;
        }
    }

    /**
     * Получение итогового значения
     */
    get successResult() {
        return this.email || this.phone;
    }

    /**
     * Отмена восстановления
     */
    onCancel = () => {
        window.location = `${BASE_URL}/auth/login`;
    };

    /**
     * Проверка на авторизацию
     */
    check401Error = error => {
        if (error.response.status === 401) {
            //Отправляем авторизоваться
            window.location = `${BASE_URL}/auth/login`;
            return true;
        }
        return false;
    };

    /**
     * Восстановление пароля
     */
    sendRecoveryPassword = () => {
        const url = this.type === "email" ? "auth/restore-password-by-email" : "auth/restore-password-by-phone";
        const data = this.type === "email" ? {emailAddress: this.email} : {msisdn: this.phone.replace(/\D/g, '')};
        axios.post(`${BASE_URL}/${url}`, data)
            .then(() => {
                this.success = true;
            })
            .catch(error => {
                if (this.check401Error(error)) {
                    return;
                }
                const localizedMessage = get(error.response, "data.localizedMessage", null);
                const message = get(error.response, "data.message", null);
                this.toastStore.showError("Внимание", localizedMessage || message);
            })
    };
}
