import React, {useState} from "react";
import styled from "styled-components";
import Button from "../Elements/Button";
import {useNavigate} from "react-router";
import routes from "../routes";
import Input from "../Elements/Input";
import {useRootStore} from "../RootStoreProvider";

const Page = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;

const Title = styled.p`
    margin: 0;
    color: rgba(0, 0, 0, 0.9);
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    margin-bottom: 40px;
    
    @media (max-width: 435px) {
        font-size: 22px;
        margin-bottom: 24px;
    }
`;

const Subtitle = styled.p`
    margin: 0;
    color: rgba(0, 0, 0, 0.9);
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    margin-bottom: 40px;
    
    @media (max-width: 435px) {
        margin-bottom: 24px;
    }
`;

const Form = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 40px 30px;
    width: 436px;
    background: #FFFFFF;
    box-shadow: 0 4px 30px rgba(194, 201, 206, 0.35);
    border-radius: 24px;
    margin-top: 100px;
    position: relative;
    z-index: 999;
    
    @media (max-width: 435px) {
        margin-top: 0;
        width: 100%;
        padding: 24px 16px;
        border-radius: 0 0 24px 24px;
    }
`;

const UnderFormText = styled.a`
    margin: 24px 0;
    text-decoration: none;
    font-weight: 600;
    font-size: 16px;
    height: 52px;
    line-height: 160%;
    color: #FF5145;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    transition: all 300ms ease-in-out;
    cursor: pointer;

    &:hover, &:active {
        background: rgba(255, 81, 69, 0.3);
        color: #FFFFFF;
    }
`;

/**
 * Страница редактирования своих данных
 */
const MyDataEditPage = () => {
    const navigate = useNavigate();
    const store = useRootStore();
    const [loading, setLoading] = useState(false);
    const [surname, setSurname] = useState(store.userStore.surname);
    const [name, setName] = useState(store.userStore.name);
    const [patronymic, setPatronymic] = useState(store.userStore.patronymic);

    const disabledForm = loading || !surname || !name;

    /**
     * Подтверждение введенных изменений
     */
    const onAccept = async () => {
        setLoading(true);
        await store.userStore.updateFio(navigate, surname, name, patronymic);
        setLoading(false);
    };

    return (
        <Page>
            <Form>
                <Title>Информационные системы Московской области</Title>
                <Subtitle>Изменение учетной записи</Subtitle>
                <Input placeholder="Введите вашу фамилию"
                       maxLength={29}
                       onChange={setSurname}
                       value={surname}/>
                <Input placeholder="Введите ваше имя"
                       maxLength={29}
                       onChange={setName}
                       value={name}/>
                <Input placeholder="Введите ваше отчество"
                       maxLength={29}
                       onChange={setPatronymic}
                       value={patronymic}/>
                <Button title={"Сохранить"}
                        isDisabled={disabledForm}
                        onClick={onAccept}
                        margin={"12px 0 0 0"}/>
                <Button title={"Назад"}
                        isDisabled={loading}
                        type={"border_red"}
                        margin={"12px 0 0 0"}
                        onClick={() => navigate(routes.info.path)}/>
            </Form>
            {
                store.userStore.user?.hasPassword &&
                <UnderFormText onClick={() => loading ? null : navigate(routes.changePassword.path)}>
                    Сменить пароль
                </UnderFormText>
            }
        </Page>
    )
};

export default MyDataEditPage;
