import React from "react";
import styled from "styled-components";

const Title = styled.div`
    margin: 0;
    width: 100%;
    text-align: center;
    color: rgba(0, 0, 0, 0.9);
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    
    @media (max-width: 435px) {
        font-size: 22px;
    }
`;

const Form = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 40px 30px;
    width: 436px;
    background: #FFFFFF;
    box-shadow: 0 4px 30px rgba(194, 201, 206, 0.35);
    border-radius: 24px;
    margin-top: 100px;
    margin-bottom: 20px;
    position: relative;
    z-index: 999;
    
    @media (max-width: 435px) {
        margin-top: 0;
        width: 100%;
        padding: 24px 16px;
        border-radius: 0 0 24px 24px;
    }
`;

/**
 * Страница для некорректных маршрутов
 */
const Error404Page = () => {
    return (
        <Form>
            <Title>Страница не найдена</Title>
        </Form>
    )
};

export default Error404Page;
