import React, {useState} from "react";
import styled, {css} from "styled-components";

const ElementWrapper = styled.div`
    position: relative;
    width: 100%;
`;

const SvgWrapper = styled.div`
    position: absolute;
    cursor: pointer;
    top: 14px;
    right: 16px;
`;

const InputWrapper = styled.input`
    width: 100%;
    height: ${({height})=> height || "52px"};
    background: #FFFFFF;
    border: 2px solid ${({isError}) => isError ? "#D61C0F" : "rgba(0, 0, 0, 0.08)"};
    border-radius: 8px;
    font-size: ${({fontSize}) => fontSize};
    padding: 10px 16px;
    margin-bottom: ${({margin})=> margin || "12px"};
    text-align: ${({textAlign})=> textAlign || "left"};
    line-height: normal;
    
    &:focus-visible {
        outline: none;
    }
    
    &:focus {
        border-color: rgba(255, 81, 69, 0.2);
    }
    
    &::placeholder {
        color: rgba(0, 0, 0, 0.5);
        font-weight: 400;
        font-size: 16px;
        width: 100%;
        font-family: 'Open Sans', sans-serif;
        ${({inputType}) =>
                inputType === "code"
                        ? css`
            transform: translateY(-4px);
          `
                        : css``}      
      
    }
`;

const Input = ({placeholder, maxLength, onChange, value, type="text", margin, isError, textAlign, fontSize="16px", inputType, height, iconColor ="black"}) => {
    const [show, setShow] = useState(false);
    return (
        <ElementWrapper>
            <InputWrapper placeholder={placeholder}
                          margin={margin}
                          maxLength={maxLength}
                          isError={isError}
                          textAlign={textAlign}
                          fontSize={fontSize}
                          value={value}
                          type={show ? "text" : type}
                          height={height}
                          inputType={inputType}
                          onChange={e => onChange(e.target.value)}/>
            {
                type === "password" &&
                <SvgWrapper onClick={() => setShow(!show)}>
                    {
                        show ?
                            <svg xmlns="http://www.w3.org/2000/svg"
                                 width="24" height="24"
                                 viewBox="0 0 24 24" fill="none">
                                <path
                                    d="M12 15.75C13.1833 15.75 14.1875 15.3375 15.0125 14.5125C15.8375 13.6875 16.25 12.6833 16.25 11.5C16.25 10.3167 15.8375 9.3125 15.0125 8.4875C14.1875 7.6625 13.1833 7.25 12 7.25C10.8167 7.25 9.8125 7.6625 8.9875 8.4875C8.1625 9.3125 7.75 10.3167 7.75 11.5C7.75 12.6833 8.1625 13.6875 8.9875 14.5125C9.8125 15.3375 10.8167 15.75 12 15.75ZM12 14.3C11.2167 14.3 10.5542 14.0292 10.0125 13.4875C9.47083 12.9458 9.2 12.2833 9.2 11.5C9.2 10.7167 9.47083 10.0542 10.0125 9.5125C10.5542 8.97083 11.2167 8.7 12 8.7C12.7833 8.7 13.4458 8.97083 13.9875 9.5125C14.5292 10.0542 14.8 10.7167 14.8 11.5C14.8 12.2833 14.5292 12.9458 13.9875 13.4875C13.4458 14.0292 12.7833 14.3 12 14.3ZM12 19C9.56667 19 7.36667 18.3083 5.4 16.925C3.43333 15.5417 1.96667 13.7333 1 11.5C1.96667 9.26667 3.43333 7.45833 5.4 6.075C7.36667 4.69167 9.56667 4 12 4C14.4333 4 16.6333 4.69167 18.6 6.075C20.5667 7.45833 22.0333 9.26667 23 11.5C22.0333 13.7333 20.5667 15.5417 18.6 16.925C16.6333 18.3083 14.4333 19 12 19ZM12 17.5C14.0167 17.5 15.8708 16.9542 17.5625 15.8625C19.2542 14.7708 20.5417 13.3167 21.425 11.5C20.5417 9.68333 19.2542 8.22917 17.5625 7.1375C15.8708 6.04583 14.0167 5.5 12 5.5C9.98333 5.5 8.12917 6.04583 6.4375 7.1375C4.74583 8.22917 3.45 9.68333 2.55 11.5C3.45 13.3167 4.74583 14.7708 6.4375 15.8625C8.12917 16.9542 9.98333 17.5 12 17.5Z"
                                    fillOpacity="0.5" fill={iconColor}/>
                            </svg>
                        :
                            <svg xmlns="http://www.w3.org/2000/svg"
                                 width="25" height="24"
                                 viewBox="0 0 25 24" fill="none">
                                <path
                                    d="M16.225 13.525L15.125 12.425C15.5583 11.2417 15.3333 10.2583 14.45 9.475C13.5667 8.69167 12.6083 8.49167 11.575 8.875L10.475 7.775C10.7583 7.59167 11.075 7.45833 11.425 7.375C11.775 7.29167 12.1333 7.25 12.5 7.25C13.6833 7.25 14.6875 7.6625 15.5125 8.4875C16.3375 9.3125 16.75 10.3167 16.75 11.5C16.75 11.8667 16.7042 12.2292 16.6125 12.5875C16.5208 12.9458 16.3917 13.2583 16.225 13.525ZM19.45 16.75L18.45 15.75C19.2667 15.15 19.9792 14.4792 20.5875 13.7375C21.1958 12.9958 21.6417 12.25 21.925 11.5C21.0917 9.65 19.8417 8.1875 18.175 7.1125C16.5083 6.0375 14.7 5.5 12.75 5.5C12.05 5.5 11.3333 5.56667 10.6 5.7C9.86667 5.83333 9.29167 5.99167 8.875 6.175L7.725 5C8.30833 4.73333 9.05417 4.5 9.9625 4.3C10.8708 4.1 11.7583 4 12.625 4C15.0083 4 17.1875 4.67917 19.1625 6.0375C21.1375 7.39583 22.5833 9.21667 23.5 11.5C23.0667 12.5667 22.5083 13.5417 21.825 14.425C21.1417 15.3083 20.35 16.0833 19.45 16.75ZM20.9 22.4L16.7 18.275C16.1167 18.5083 15.4583 18.6875 14.725 18.8125C13.9917 18.9375 13.25 19 12.5 19C10.0667 19 7.85833 18.3208 5.875 16.9625C3.89167 15.6042 2.43333 13.7833 1.5 11.5C1.83333 10.6333 2.29583 9.7875 2.8875 8.9625C3.47917 8.1375 4.2 7.35 5.05 6.6L1.9 3.45L2.95 2.375L21.875 21.3L20.9 22.4ZM6.075 7.65C5.45833 8.1 4.8625 8.69167 4.2875 9.425C3.7125 10.1583 3.3 10.85 3.05 11.5C3.9 13.35 5.17917 14.8125 6.8875 15.8875C8.59583 16.9625 10.5333 17.5 12.7 17.5C13.25 17.5 13.7917 17.4667 14.325 17.4C14.8583 17.3333 15.2583 17.2333 15.525 17.1L13.925 15.5C13.7417 15.5833 13.5167 15.6458 13.25 15.6875C12.9833 15.7292 12.7333 15.75 12.5 15.75C11.3333 15.75 10.3333 15.3417 9.5 14.525C8.66667 13.7083 8.25 12.7 8.25 11.5C8.25 11.25 8.27083 11 8.3125 10.75C8.35417 10.5 8.41667 10.275 8.5 10.075L6.075 7.65Z"
                                    fill={iconColor} fillOpacity="0.4"/>
                            </svg>
                    }
                </SvgWrapper>
            }
        </ElementWrapper>
    )
};

export default Input;
