import {makeAutoObservable} from "mobx";
import {validateEmail, validatePhone} from "../functions";
import routes from "../routes";
import axios from "axios";
import {BASE_URL, LOCALSTORAGE_KEYS} from "../config";
import get from "lodash/get";

export default class Data {
    name = "";
    patronymic = "";
    surename = "";
    msisdn = "";
    emailAddress = "";
    password = "";
    passwordRepeat = "";
    access = false;
    userStore = null;
    navigate = null;
    objId = "";
    state = "created";
    errorFields = [];
    redirectUri= "";

    constructor(navigate, userStore, redirectUri, toastStore) {
        this.navigate = navigate;
        this.userStore = userStore;
        this.redirectUri = redirectUri;
        this.toastStore = toastStore;
        makeAutoObservable(this);
    }

    /**
     * Проверка валидности пароля
     */
    get isPasswordNotValid() {
        return this.password && this.password.length < 6
    }

    /**
     * Флаг блокировки формы 
     */
    get disabledForm() {
        return !this.surename || !this.name || !this.password || !this.passwordRepeat || this.password.length < 6 ||
            this.password !== this.passwordRepeat || !this.access || (this.msisdn.replace(/\D/g, '').length !== 11 && !validateEmail(this.emailAddress))
    }

    /**
     *  регистрация пользователя
     */
    registration = async () => {
        const data = {password: this.password};
        if (
            this.msisdn &&
            this.msisdn.replace(/\D/g, '').length > 0 &&
            this.msisdn.replace(/\D/g, '').length < 11
        ) {
            this.toastStore.showError("Внимание", "Введите корректный номер телефона");
            return;
        }
        axios.put(`${BASE_URL}/user-registrations/${this.objId}/start`, data)
            .then(() => {
                this.navigate(routes.registrationAccept.path);
            })
            .catch(error => {
                const localizedMessage = get(error.response, "data.localizedMessage", null);
                const message = get(error.response, "data.message", null);
                this.toastStore.showError("Внимание", localizedMessage || message);
            })
    };

    /**
     * Создание нового объекта регситрации для сохраненний введеных пользователем значений
     */
    createRegistrationObject = () => {
        const data = {
            name: "",
            surname: "",
            patronymic: "",
            msisdn: "",
            emailAddress: "",
            redirectUri: this.redirectUri
        };
        //Создание новой регистрации
        axios.post(`${BASE_URL}/user-registrations`, data)
            .then(response => {
                this.objId = response.data.id;
                localStorage.setItem(LOCALSTORAGE_KEYS.REGISTRATION_OBJ_ID, this.objId);
            })
            .catch(error => {
                const localizedMessage = get(error.response, "data.localizedMessage", null);
                const message = get(error.response, "data.message", null);
                this.toastStore.showError("Внимание", localizedMessage || message);
            })
    };

    /**
     * Проверка наличий объекта регистрации через АПИ
     */
    checkRegistration = () => {
        //получаем url параметр redirect_uri
        const objId = localStorage.getItem(LOCALSTORAGE_KEYS.REGISTRATION_OBJ_ID);
        if (objId) {
            //Получение ранее созданной регистрации
            axios.get(`${BASE_URL}/user-registrations/${objId}`)
                .then(response => {
                    this.objId = objId;
                    this.name = response.data.name;
                    this.patronymic = response.data.patronymic;
                    this.surename = response.data.surname;
                    this.msisdn = response.data.msisdn;
                    this.emailAddress = response.data.emailAddress;
                    this.state = response.data.state;
                    if (this.state === "started") {
                        this.navigate(routes.registrationAccept.path);
                    }
                })
                .catch(error => {
                    if (error.response.status === 404) {
                        localStorage.removeItem(LOCALSTORAGE_KEYS.REGISTRATION_OBJ_ID);
                        this.createRegistrationObject();
                        return null;
                    }
                    const localizedMessage = get(error.response, "data.localizedMessage", null);
                    const message = get(error.response, "data.message", null);
                    this.toastStore.showError("Внимание", localizedMessage || message);
                })
        }
        else {
            this.createRegistrationObject();
        }
    };

    /**
     * Обновление объекта регистрации
     */
    updateRegistration = () => {
        const data = {
            name: this.name,
            surname: this.surename,
            patronymic: this.patronymic,
            msisdn: this.msisdn ? this.msisdn.replace(/\D/g, '') : null,
            emailAddress: this.emailAddress,
            redirectUri: this.redirectUri
        };
        axios.put(`${BASE_URL}/user-registrations/${this.objId}`, data)
            .then(response => {
                this.objId = response.data.id;
                this.errorFields = [];
            })
            .catch(error => {
                this.errorFields = get(error.response, "data.errorFields", []);
                const msg = this.errorFields.map(i => i.description).join("\n");
                this.toastStore.showError("Внимание", msg);
            })
    };
}
