import React from "react";
import styled from "styled-components";
import Button from "../Button";

const Wrapper = styled.div`
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ModalWrapper = styled.div`
    background: #FFFFFF;
    max-width: 500px;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 24px;
    border-radius: 24px;
`;

const Title = styled.p`
    margin: 0;
    color: rgba(0, 0, 0, 0.9);
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    margin-bottom: 24px;
    
    @media (max-width: 435px) {
        font-size: 22px;
    }
`;

const Subtitle = styled.p`
    margin: 0;
    color: rgba(0, 0, 0, 0.9);
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    margin-bottom: 24px;
`;

const ButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const ModalAccept = ({onClose, onAccept, text}) => {
    return (
        <Wrapper>
            <ModalWrapper>
                <Title>Внимание</Title>
                <Subtitle>{text}</Subtitle>
                <ButtonWrapper>
                    <Button title={"Отменить"}
                            onClick={onClose}
                            type={"border_red"}
                            margin={"0 12px 0 0"}/>
                    <Button title={"Продолжить"}
                            onClick={onAccept}
                            margin={"0"}/>
                </ButtonWrapper>
            </ModalWrapper>
        </Wrapper>
    )
};

export default ModalAccept;
