import UserStore from "./UserStore";
import ToastStore from "./ToastStore";

/**
 * Общее хранилище для объединение остальных
 */
export default class RootStore {
    userStore;

    constructor() {
        this.toastStore = new ToastStore(this);
        this.userStore = new UserStore(this);
    }
}
