import React, {useState} from "react";
import styled from "styled-components";
import {validateEmail} from "../../functions";

const InputWrapper = styled.input`
    width: 100%;
    height: 52px;
    background: #FFFFFF;
    border: 2px solid ${({isError}) => isError ? "#D61C0F" : "rgba(0, 0, 0, 0.08)"};
    border-radius: 8px;
    padding: 10px 16px;
    font-size: 16px;
    margin-bottom: ${({isError}) => isError ? "4px" : "12px"};
    
    &:focus-visible {
        outline: none;
    }
    
    &:focus {
        border-color: rgba(255, 81, 69, 0.2);
    }
    
    &::placeholder {
        color: rgba(0, 0, 0, 0.5);
        font-weight: 400;
        font-size: 16px;
        line-height: 160%;
        font-family: 'Open Sans', sans-serif;
    }
`;

const ErrorWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #D61C0F;
    margin-bottom: 12px;
`;

const Dot = styled.div`
    width: 6px;
    height: 6px;
    background: #D61C0F;
    margin-right: 8px;
    border-radius: 50px;
`;

const EmailInput = ({placeholder, onChange, value, isDisabled, isError, onChangeError = () => {}}) => {
    const [valid, setValid] = useState(true);

    const onBlur = () => {
        setValid(validateEmail(value));
        onChangeError(!validateEmail(value));
    };

    const onChangeInner = e => {
        onChange(e.target.value);
        onChangeError(!validateEmail(e.target.value));
    };

    const isErrorValidate = value ? !valid : false;

    const onKeyPress = e => {
        if (e.key === ' ') {
            e.preventDefault();
        }
    };

    return (
        <>
            <InputWrapper placeholder={placeholder}
                          isError={isErrorValidate || isError}
                          value={value}
                          disabled={isDisabled}
                          onBlur={onBlur}
                          name={"email"}
                          onKeyPress={onKeyPress}
                          onChange={onChangeInner}/>
            {
                (isError || isErrorValidate) &&
                <ErrorWrapper>
                    <Dot/>Введите корректные данные
                </ErrorWrapper>
            }
        </>
    )
};

export default EmailInput;
