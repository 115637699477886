import React from "react";
import RegistrationPage from "./RegistrationPage";
import AcceptEmailPage from "./AcceptEmailPage";
import Error404Page from "./Error404Page";
import MainPage from "./MainPage";
import MyDataPage from "./MyDataPage";
import MyDataEditPage from "./MyDataEditPage";
import AcceptPhonePage from "./AcceptPhonePage";
import BindEsiaPage from "./BindEsiaPage";
import ChangePasswordPage from "./ChangePasswordPage";
import RecoveryPasswordSuccessPage from "./RecoveryPasswordSuccessPage";
import RecoveryPasswordPage from "./RecoveryPasswordPage";
import TwoStepSettingsPage from "./TwoStepSettingsPage";
import RequestsPage from "./RequestsPage";
import RegistrationAcceptPage from "./RegistrationAcceptPage";
import AuthSuccessPage from "./AuthSuccessPage";
import CreateRequestPage from "./CreateRequestPage";
import CancelRequestPage from "./CancelRequestPage";

const routes = {
    main: {
        path: "/",
        element: <MainPage/>
    },
    infoEdit: {
        path: "/info/edit",
        element: <MyDataEditPage/>
    },
    info: {
        path: "/info",
        element: <MyDataPage/>
    },
    registration: {
        path: "/registration",
        element: <RegistrationPage/>
    },
    registrationAccept: {
        path: "/registration_accept",
        element: <RegistrationAcceptPage/>
    },
    bindEsia: {
        path: "/bind/esia",
        element: <BindEsiaPage/>
    },
    recoveryPassword: {
        exact: true,
        path: "/recovery_password",
        element: <RecoveryPasswordPage/>
    },
    recoveryPasswordSuccess: {
        exact: true,
        path: "/rps",
        element: <RecoveryPasswordSuccessPage/>
    },
    changePassword: {
        path: "/change_password",
        element: <ChangePasswordPage/>
    },
    twoStepEntrySettings: {
        path: "/two-step-settings",
        element: <TwoStepSettingsPage/>
    },
    requests: {
        path: "/requests",
        element: <RequestsPage/>
    },
    createRequest: {
        path: "/create_request",
        element: <CreateRequestPage/>
    },
    cancelRequest: {
        path: "/cancel_request",
        element: <CancelRequestPage/>
    },
    acceptEmail: {
        path: "/accept_email",
        element: <AcceptEmailPage/>
    },
    acceptPhone: {
        path: "/accept_phone",
        element: <AcceptPhonePage/>
    },
    authSuccess: {
        path: "/login/oauth2/code/mosso",
        element: <AuthSuccessPage/>
    },
    error404: {
        path: "*",
        element: <Error404Page/>
    },
};

export default routes;

export const blackListCheckUserAccount = [
    routes.error404,
    routes.authSuccess,
    routes.registrationAccept,
    routes.registration,
    routes.recoveryPassword,
    routes.recoveryPasswordSuccess,
];
