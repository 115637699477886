import React from "react";


const StatusIconSuccess = ({fill}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
            <circle cx="9" cy="9" r="6" fill={fill}/>
            <path d="M7.125 9L8.51893 10.3939C8.57751 10.4525 8.67249 10.4525 8.73107 10.3939L11.625 7.5" stroke="white" strokeWidth="1.2"/>
        </svg>
    )
};

export default StatusIconSuccess;
