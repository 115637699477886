import React, {useEffect} from "react";
import {Routes, Route} from "react-router-dom";
import routes, {blackListCheckUserAccount} from "./routes";
import styled from "styled-components";
import {useRootStore} from "./RootStoreProvider";
import {useNavigate} from "react-router";
import {VERSION} from "./config";
import Toast from "./Elements/Toast";

const Footer = styled.div`
    width: 359px;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: rgba(0, 0, 0, 0.5);
    margin-bottom: 40px;
    display: flex;
    
    a {
        margin: 0;
        text-decoration: none;
        color: rgba(0, 0, 0, 0.5);
    }
    
    b {
        color: rgba(0, 0, 0, 0.9);
    }
`;

const Version = styled.div`
    position: fixed;
    bottom: 10px;
    left: 10px;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: rgba(0, 0, 0, 0.5);
`;

const Main = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
`;

function App() {
  const store = useRootStore();
  const navigate = useNavigate();

  useEffect(() => {
      if (!blackListCheckUserAccount.find(i => i.path === window.location.pathname)) {
          store.userStore.getUserAuth(navigate);
      }
  }, []);

  return (
     <>
        <Toast />
       <Main>
          <Routes>
            {Object.values(routes).map((route, i) => <Route key={i} {...route}/>)}
          </Routes>
       </Main>
        <Footer>
            <a href={"/agreement.pdf"} target={"_blank"}>Аутентифицируясь, вы принимаете <b>соглашение на обработку персональных данных</b></a>
            <Version>v. {VERSION}</Version>
        </Footer>
     </>
  );
}

export default App;
