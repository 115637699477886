import React from "react";
import styled from "styled-components";
import Button from "../Elements/Button";
import {useLocation, useNavigate} from "react-router";
import routes from "../routes";
import {useRootStore} from "../RootStoreProvider";
import {observer} from "mobx-react";

const Page = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    @media (max-width: 435px) {
      justify-content: flex-start;
    }
`;

const Title = styled.p`
    margin: 0;
    color: rgba(0, 0, 0, 0.9);
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    margin-bottom: 40px;
    
    @media (max-width: 435px) {
        font-size: 22px;
        margin-bottom: 24px;
    }
`;

const Subtitle = styled.p`
    margin: 0;
    color: rgba(0, 0, 0, 0.9);
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    margin-bottom: 40px;

    @media (max-width: 435px) {
        margin-bottom: 24px;
    }
    
    b {
        color: rgba(255, 81, 69, 1);
    }
`;

const Form = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 40px 30px;
    width: 436px;
    background: #FFFFFF;
    box-shadow: 0 4px 30px rgba(194, 201, 206, 0.35);
    border-radius: 24px;
    margin-top: 100px;
    position: relative;
    z-index: 999;
    
    @media (max-width: 435px) {
        margin-top: 0;
        width: 100%;
        padding: 24px 16px;
        border-radius: 0 0 24px 24px;
    }
`;

const Text = styled.p`
    margin: 0;
    color: rgba(0, 0, 0, 0.9);
    font-weight: 600;
    font-size: 16px;
    line-height: 160%;
    margin-bottom: 40px;
    margin-top: 24px;
    
    b {
        color: rgba(255, 81, 69, 1);
    }
`;

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

/**
 * Страница отказа от слияния
 */
const CancelRequestPage = observer(() => {
    const store = useRootStore();
    const navigate = useNavigate();
    const {state} = useLocation();
    const { request_id, type, username } = state;

    /**
     * Подтверждение отказа от слияния
     */
    const onAccept = () => {
        if (type === "cancel") {
            store.userStore.cancelOutgoingRequest(request_id)
                .then(result => {
                    if (result) {
                        navigate(routes.requests.path);
                    }
                });
        }
        else {
            store.userStore.declineIncomingRequest(request_id)
                .then(result => {
                    if (result) {
                        navigate(routes.requests.path);
                    }
                });
        }
    };

    return (
        <Page>
            <Form>
                <Title>Информационные системы Московской области</Title>
                <Subtitle>Объдинение учетных записей</Subtitle>
                <Text>Вы уверены, что хотите отменить запрос на объединение с <b>{username}</b>?</Text>
                <Wrapper>
                    <Button title={"Назад"}
                            type={"border_red"}
                            margin={"0 2px 0 0"}
                            onClick={() => navigate(routes.requests.path)}/>
                    <Button title={"Отменить"}
                            margin={"0 0 0 2px"}
                            onClick={onAccept}/>
                </Wrapper>
            </Form>
        </Page>
    )
});

export default CancelRequestPage;
