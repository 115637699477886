import React, {useEffect, useState} from "react";
import styled from "styled-components";
import Button from "../Elements/Button";
import {useNavigate} from "react-router";
import routes from "../routes";
import RadioButton from "../Elements/RadioButton";
import {useRootStore} from "../RootStoreProvider";
import {observer} from "mobx-react";

const Page = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    @media (max-width: 435px) {
      justify-content: flex-start;
    }
`;

const Title = styled.p`
    margin: 0;
    color: rgba(0, 0, 0, 0.9);
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    margin-bottom: 40px;
    
    @media (max-width: 435px) {
        font-size: 22px;
        margin-bottom: 24px;
    }
`;

const Subtitle = styled.p`
    margin: 0;
    color: rgba(0, 0, 0, 0.9);
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    margin-bottom: 40px;
    
    @media (max-width: 435px) {
        margin-bottom: 24px;
    }
    
    b {
        font-weight: 600;
        color: #FF5145;
    }
`;

const Form = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 40px 30px;
    width: 436px;
    background: #FFFFFF;
    box-shadow: 0 4px 30px rgba(194, 201, 206, 0.35);
    border-radius: 24px;
    margin-top: 100px;
    position: relative;
    z-index: 999;
    
    @media (max-width: 435px) {
        margin-top: 0;
        width: 100%;
        padding: 24px 16px;
        border-radius: 0 0 24px 24px;
    }
`;

const TYPES = {
    OFF: "OFF",
    EMAIL: "EMAIL",
    MSISDN: "MSISDN"
};

const TwoStepSettingsPage = observer(() => {
    const store = useRootStore();
    const navigate = useNavigate();
    const [firstVariant, setFirstVariant] = useState();
    const [wasSave, setWasSave] = useState(false);

    useEffect(() => {
        store.userStore.getUserAuth()
            .then(() => {
                setFirstVariant(store.userStore.twoFactorAuthType);
            })
    }, []);

    const onSave = () => {
        setWasSave(true);
        store.userStore.change2fa()
            .then(() => {
                navigate(routes.main.path);
                setFirstVariant(store.userStore.twoFactorAuthType);
            })
    };

    const onCancel = () => {
        if (!wasSave) {
            store.userStore.twoFactorAuthType = firstVariant;
        }
        navigate(routes.main.path);
    };

    return (
        <Page>
            <Form>
                <Title>Информационные системы Московской области</Title>
                <Subtitle>Настройка двухэтапного входа</Subtitle>
                <RadioButton id={"email"}
                             value={store.userStore.twoFactorAuthType === TYPES.EMAIL}
                             disabled={!store.userStore.emailAddressConfirmed}
                             onChange={() => store.userStore.twoFactorAuthType = TYPES.EMAIL}
                             title={<>Подтверждать вход по Email <b>{store.userStore.emailAddressConfirmed}</b></>}/>
                <RadioButton id={"phone"}
                             value={store.userStore.twoFactorAuthType === TYPES.MSISDN}
                             disabled={!store.userStore.phoneNumberConfirmed}
                             onChange={() => store.userStore.twoFactorAuthType = TYPES.MSISDN}
                             title={<>Подтверждать вход по SMS<br/><b>{store.userStore.phoneNumberConfirmed}</b></>}/>
                <RadioButton id={"nothing"}
                             value={store.userStore.twoFactorAuthType === TYPES.OFF}
                             onChange={() => store.userStore.twoFactorAuthType = TYPES.OFF}
                             title={<>Не использовать двухэтапный вход</>}/>
                <Button title={"Сохранить"}
                        margin={"12px 0 12px 0"}
                        isDisabled={store.userStore.twoFactorAuthType === firstVariant}
                        onClick={onSave}/>
                <Button title={"Отменить"}
                        type={"transparent_red"}
                        margin={"0"}
                        onClick={onCancel}/>
            </Form>
        </Page>
    )
});

export default TwoStepSettingsPage;
