import React from "react";


const EsiaIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
            <g clipPath="url(#clip0_220_1837)">
                <path d="M30.8916 10.615C30.7657 8.9659 29.507 6.85165 28.2482 5.8368C26.8327 4.79822 25.362 3.83834 23.8426 2.96143C22.3347 2.06071 20.7801 1.24203 19.1853 0.5089C18.3334 0.158682 17.4199 -0.0139308 16.5 0.00148266C15.5797 -0.0180031 14.6654 0.154763 13.8147 0.5089C12.2201 1.24255 10.6655 2.06121 9.15732 2.96143C6.72377 4.4414 4.75172 5.8368 4.75172 5.8368C4.09824 6.38795 3.54478 7.039 3.10786 7.76045C3.05775 7.8432 3.1182 7.94745 3.21493 7.94745H5.26002C5.30939 7.94745 5.35618 7.92565 5.38856 7.8884C5.62442 7.61705 5.88257 7.36325 6.16147 7.13C6.16147 7.13 7.89687 5.9122 10.0384 4.62056C11.3656 3.83492 12.7337 3.12045 14.1369 2.48018C14.8855 2.17111 15.6901 2.02033 16.5 2.03734C17.3095 2.02389 18.1134 2.17453 18.863 2.48018C20.2664 3.12 21.6346 3.83449 22.9615 4.62056C24.2985 5.38585 25.5928 6.2236 26.8384 7.13C27.9461 8.01565 29.0538 9.8608 29.1646 11.3C29.1646 11.3 29.423 13.4405 29.46 15.9757C29.4577 17.5414 29.3714 19.1059 29.2015 20.6624C28.9439 22.2921 28.1044 23.7735 26.8384 24.8325C26.8384 24.8325 25.103 26.0872 22.9615 27.3787C21.6343 28.1644 20.2662 28.8789 18.863 29.5191C18.1144 29.8282 17.3098 29.979 16.5 29.962C15.6912 29.979 14.8873 29.8321 14.1369 29.5303C12.7335 28.8904 11.3654 28.176 10.0384 27.3899C7.89687 26.1352 6.16147 24.8435 6.16147 24.8435C5.93612 24.6633 5.71072 24.4434 5.49344 24.1952C5.46105 24.1582 5.41444 24.1367 5.36528 24.1367H3.31263C3.21466 24.1367 3.15436 24.2433 3.2066 24.3262C3.66278 25.0499 4.20725 25.6946 4.75172 26.1336C4.75172 26.1336 6.72377 27.6136 9.15732 29.0513C10.6652 29.952 12.2199 30.7706 13.8147 31.5037C14.6675 31.8496 15.5809 32.018 16.5 31.9985C17.4202 32.018 18.3345 31.8452 19.1853 31.4911C20.7798 30.7574 22.3345 29.9387 23.8426 29.0385C26.2762 27.5585 28.2482 26.1209 28.2482 26.1209C29.6869 24.9075 30.6408 23.2101 30.9335 21.3427C31.1266 19.5592 31.2247 17.7666 31.2272 15.9725C31.1853 13.0675 30.8916 10.615 30.8916 10.615Z" fill="white"/>
                <path d="M11.5505 20.3102H10.558C10.5248 20.3102 10.5007 20.278 10.5097 20.2458C10.6757 19.681 10.8486 19.0418 10.9683 18.3331C11.0909 17.6002 11.1553 16.9247 11.1825 16.3217C11.1835 16.2946 11.2056 16.2734 11.2327 16.2734H14.1822C14.21 16.2734 14.2325 16.2959 14.2325 16.3237V20.2599C14.2325 20.2877 14.21 20.3102 14.1822 20.3102H13.2158C13.188 20.3102 13.1655 20.2877 13.1655 20.2599V17.2197H12.1953C12.1597 17.6684 12.0995 18.1504 12.001 18.6592C11.8863 19.2552 11.7506 19.7958 11.5977 20.276C11.5917 20.2961 11.5716 20.3102 11.5505 20.3102Z" fill="white"/>
                <path d="M2.22461 10.4371C2.22461 10.4093 2.2471 10.3867 2.27484 10.3867H5.11841C5.15392 10.3867 5.17795 10.4234 5.16415 10.4565L4.81201 11.3023C4.80271 11.3246 4.78144 11.3348 4.76125 11.3328C4.75959 11.3329 4.75791 11.333 4.75622 11.333H3.28949V14.3732C3.28949 14.401 3.267 14.4235 3.23926 14.4235H2.27484C2.2471 14.4235 2.22461 14.401 2.22461 14.3732V10.4371Z" fill="white"/>
                <path d="M19.5488 16.3237C19.5488 16.2959 19.5713 16.2734 19.5991 16.2734H22.4486C22.4842 16.2734 22.5083 16.3101 22.4944 16.3432L22.1416 17.189C22.1322 17.2113 22.1109 17.2215 22.0907 17.2194C22.089 17.2196 22.0874 17.2197 22.0857 17.2197H20.6159V20.2599C20.6159 20.2877 20.5934 20.3102 20.5656 20.3102H19.5991C19.5713 20.3102 19.5488 20.2877 19.5488 20.2599V16.3237Z" fill="white"/>
                <path d="M2.27491 16.2734C2.23999 16.2734 2.21572 16.3078 2.22771 16.3402L3.69605 20.3108C3.48885 20.7465 3.25864 21.205 2.99537 21.7161C2.97814 21.7496 3.00215 21.79 3.03985 21.79H4.08442C4.10339 21.79 4.12088 21.7791 4.12944 21.7623C4.90238 20.1894 5.47115 18.7951 6.24881 16.3399C6.25941 16.3075 6.23541 16.2734 6.20116 16.2734H5.23097C5.20846 16.2734 5.18856 16.2887 5.1824 16.3103C4.88301 17.36 4.60697 18.1816 4.28699 18.9734L3.30062 16.306C3.29337 16.2864 3.27452 16.2734 3.25342 16.2734H2.27491Z" fill="white"/>
                <path d="M14.9312 16.2734C14.8962 16.2734 14.872 16.3078 14.884 16.3402L16.3523 20.3108C16.1451 20.7465 15.9149 21.205 15.6516 21.7161C15.6344 21.7496 15.6584 21.79 15.6961 21.79H16.7407C16.7596 21.79 16.7771 21.7791 16.7857 21.7623C17.5586 20.1894 18.1274 18.7951 18.9051 16.3399C18.9156 16.3075 18.8916 16.2734 18.8574 16.2734H17.8872C17.8647 16.2734 17.8448 16.2887 17.8387 16.3103C17.5393 17.36 17.2632 18.1816 16.9432 18.9734L15.9569 16.306C15.9496 16.2864 15.9308 16.2734 15.9097 16.2734H14.9312Z" fill="white"/>
                <path d="M9.71031 19.3571C9.70531 19.3442 9.69531 19.3332 9.68231 19.3272C9.66936 19.3213 9.65436 19.3213 9.64136 19.3253C9.40961 19.4108 8.97511 19.4804 8.67241 19.4804C8.01011 19.4804 7.69446 19.3024 7.69446 18.2908C7.69446 17.4821 7.79136 17.1011 8.67241 17.1011C8.92216 17.1011 9.15391 17.1369 9.44856 17.2225C9.47256 17.2294 9.49851 17.2185 9.50951 17.1966C9.61941 16.9907 9.75226 16.7391 9.90211 16.4307C9.90911 16.4168 9.90911 16.4009 9.90311 16.3869C9.89711 16.373 9.88511 16.3621 9.87011 16.3571C9.48256 16.2338 9.02006 16.1621 8.60351 16.1621C7.21596 16.1621 6.59766 16.8137 6.59766 18.2759C6.59766 19.7589 7.21596 20.4204 8.60351 20.4204C8.95111 20.4204 9.63636 20.3478 9.96701 20.2165C9.99301 20.2056 10.007 20.1757 9.99701 20.1499L9.71031 19.3571Z" fill="white"/>
                <path d="M13.0326 13.4703C13.0276 13.4574 13.0176 13.4465 13.0046 13.4405C12.9916 13.4345 12.9766 13.4345 12.9636 13.4385C12.7319 13.5241 12.2974 13.5937 11.9947 13.5937C11.3324 13.5937 11.0167 13.4156 11.0167 12.404C11.0167 11.5954 11.1136 11.2144 11.9947 11.2144C12.2444 11.2144 12.4762 11.2502 12.7709 11.3357C12.7948 11.3427 12.8208 11.3318 12.8318 11.3099C12.9417 11.104 13.0745 10.8523 13.2244 10.544C13.2314 10.53 13.2314 10.5141 13.2254 10.5002C13.2194 10.4863 13.2074 10.4753 13.1924 10.4704C12.8048 10.347 12.3423 10.2754 11.9258 10.2754C10.5383 10.2754 9.91992 10.9269 9.91992 12.3891C9.91992 13.8722 10.5383 14.5337 11.9258 14.5337C12.2734 14.5337 12.9587 14.4611 13.2893 14.3298C13.3153 14.3188 13.3293 14.289 13.3193 14.2631L13.0326 13.4703Z" fill="white"/>
                <path d="M7.30458 10.2754C5.94808 10.2754 5.41992 10.8679 5.41992 12.3889C5.41992 13.9331 5.94808 14.5337 7.30458 14.5337C8.67193 14.5337 9.20503 13.9331 9.20503 12.3889C9.20503 10.8679 8.67193 10.2754 7.30458 10.2754ZM7.30458 13.6474C6.68658 13.6474 6.49403 13.4764 6.49403 12.404C6.49403 11.2623 6.70433 11.1607 7.30458 11.1607C7.91668 11.1607 8.13093 11.2623 8.13093 12.404C8.13093 13.4764 7.93443 13.6474 7.30458 13.6474Z" fill="white"/>
                <path d="M26.4694 16.2734H25.5036C25.4754 16.2734 25.4532 16.2955 25.4532 16.3236V19.3652C25.244 19.4235 25.0347 19.4516 24.7993 19.4516C24.2137 19.4516 24.0437 19.2869 24.0216 18.5998V16.3236C24.0216 16.2955 23.9994 16.2734 23.9713 16.2734H23.0054C22.9772 16.2734 22.9551 16.2955 22.9551 16.3236V16.3297C22.9551 16.3297 22.9551 18.6189 22.9561 18.6219C22.9792 19.9147 23.4481 20.4209 24.6061 20.4209C25.2822 20.4209 26.0368 20.2492 26.4845 20.0784C26.5056 20.0714 26.5187 20.0513 26.5187 20.0292V16.3297V16.3236C26.5197 16.2955 26.4976 16.2734 26.4694 16.2734Z" fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_220_1837">
                    <rect width="32" height="32" fill="white" transform="translate(0.5)"/>
                </clipPath>
            </defs>
        </svg>
    )
};

export default EsiaIcon;
