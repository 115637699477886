import {makeAutoObservable} from "mobx";
import axios from "axios";
import {BASE_URL} from "../config";
import get from "lodash/get";

export default class Data {
    type = "EMAIL_ADDRESS";
    email = "";
    emailError = true;
    phone = "";
    login = "";
    success = false;
    userStore;
    toastStore;

    constructor(userStore, toastStore) {
        this.userStore = userStore;
        this.toastStore = toastStore;
        makeAutoObservable(this);
    }

    /**
     * Изменение тпиа
     */
    onChangeType = type => {
        this.type = type;
        this.email = "";
        this.phone = "";
        this.login = "";
    };

    /**
     * Флаг блокироваки формы
     */
    get disabledForm() {
        if (this.type === "EMAIL_ADDRESS") {
            if (this.email) {
                return this.emailError;
            }
            return true;
        }
        else if (this.type === "MSISDN") {
            if (this.phone) {
                return this.phone.replace(/\D/g, '').length !== 11;
            }
            return false;
        }
        else {
            return !this.login;
        }
    }

    /**
     * Получение введенного значения пользователем
     */
    get resultValue() {
        switch (this.type) {
            case "EMAIL_ADDRESS":
                return this.email;
            case "MSISDN":
                return this.phone.replace(/\D/g, '');
            case "LOGIN":
                return this.login;
        }
    }

    /**
     * Проверка на наличие авторизации 
     */
    check401Error = error => {
        if (error.response.status === 401) {
            //Отправляем авторизоваться
            window.location = `${BASE_URL}/auth/login`;
            return true;
        }
        return false;
    };

    /**
     * Создание запроса
     */
    createRequest = () => {
        const data = {
            usernameType: this.type,
            username: this.resultValue
        };
        axios.post(`${BASE_URL}/user-account/merge-requests`, data)
            .then(() => {
                this.success = true;
                this.userStore.getUserAuth();
            })
            .catch(error => {
                if (this.check401Error(error)) {
                    return;
                }
                const localizedMessage = get(error.response, "data.localizedMessage", null);
                const message = get(error.response, "data.message", null);
                this.toastStore.showError("Внимание", localizedMessage || message);
            })
    };
}
