import {makeAutoObservable} from "mobx";
import routes from "../routes";
import axios from "axios";
import {BASE_URL, LOCALSTORAGE_KEYS} from "../config";
import get from "lodash/get";

export default class Data {
    codePhone = "";
    codeEmail = "";
    registrationObg = null;
    errorConfirmEmail = false;
    errorConfirmPhone = false;

    constructor(navigate, userStore, toastStore) {
        this.navigate = navigate;
        this.userStore = userStore;
        this.toastStore = toastStore;
        makeAutoObservable(this);
    }

    /**
     * Получение объекта регистрации из АПИ
     */
    getRegistrationObj = () => {
        this.objId = localStorage.getItem(LOCALSTORAGE_KEYS.REGISTRATION_OBJ_ID);
        this.registrationObg = {"id":"e8b28fc5-f7fa-4890-88a3-f7076d0550c8","createdAt":"2022-10-19T23:49:14.989929Z","state":"started","surname":"asd","name":"asd","patronymic":"asd","emailAddress":"l.trofi@asd.rr","msisdn":"79876543210","redirectUri":null,"emailAddressConfirmed":false,"msisdnConfirmed":false};
        axios.get(`${BASE_URL}/user-registrations/${this.objId}`)
            .then(response => {
                this.registrationObg = response.data;
            })
            .catch(error => {
                const localizedMessage = get(error.response, "data.localizedMessage", null);
                const message = get(error.response, "data.message", null);
                this.toastStore.showError("Внимание", localizedMessage || message);
            })
    };

    /**
     * Подтверждение почты
     */
    confirmEmail = () => {
        const data = {
            emailAddress: this.registrationObg.emailAddress,
            code: this.codeEmail
        };
        axios.put(`${BASE_URL}/user-registrations/${this.objId}/confirm-email-address`, data)
            .then(response => {
                this.registrationObg = response.data;
            })
            .catch(error => {
                const localizedMessage = get(error.response, "data.localizedMessage", null);
                const message = get(error.response, "data.message", null);
                this.toastStore.showError("Внимание", localizedMessage || message);
                if (error.response.status === 409) {
                    this.errorConfirmEmail = true;
                }
            })
    };

    /**
     * Подтверждение телефона
     */
    confirmPhone = () => {
        const data = {
            msisdn: this.registrationObg.msisdn,
            code: this.codePhone
        };
        axios.put(`${BASE_URL}/user-registrations/${this.objId}/confirm-msisdn`, data)
            .then(response => {
                this.registrationObg = response.data;
            })
            .catch(error => {
                const localizedMessage = get(error.response, "data.localizedMessage", null);
                const message = get(error.response, "data.message", null);
                this.toastStore.showError("Внимание", localizedMessage || message);
                if (error.response.status === 409) {
                    this.errorConfirmPhone = true;
                }
            })
    };

    /**
     * Завершение регистрации и изменение статуса объекта регистрации
     */
    finishRegistration = () => {
        axios.put(`${BASE_URL}/user-registrations/${this.objId}/finish`, {})
            .then(response => {
                localStorage.removeItem(LOCALSTORAGE_KEYS.REGISTRATION_OBJ_ID);
                if (response.data.redirectUri) {
                    window.location(response.data.redirectUri);
                }
                else {
                    //Отправляем авторизоваться
                    window.location = `${BASE_URL}/auth/login`;
                }
            })
            .catch(error => {
                const localizedMessage = get(error.response, "data.localizedMessage", null);
                const message = get(error.response, "data.message", null);
                this.toastStore.showError("Внимание", localizedMessage || message);
            })
    };

    /**
     * Ввод кода подтверждения
     */
    onChangeInput = (key, value) => {
        console.log(" value",  value);
        if (key === "codePhone") {
            this.codePhone = value;
            if (value.length === 6) {
                this.confirmPhone();
            }
        }
        else {
            this.codeEmail = value;
            if (value.length === 6) {
                this.confirmEmail();
            }
        }
    };

    /**
     * Отмена регистрации
     */
    cancelRegistration = () => {
        localStorage.removeItem(LOCALSTORAGE_KEYS.REGISTRATION_OBJ_ID);
        this.navigate(routes.registration.path);
    }
}
