import React from "react";
import styled from "styled-components";
import Button from "../Elements/Button";
import {useNavigate} from "react-router";
import routes from "../routes";
import {useRootStore} from "../RootStoreProvider";
import {observer} from "mobx-react";
import EditablePhoneField from "./EditablePhoneField";
import EditableEmailField from "./EditableEmailField";
import NoAcceptIcon from "../Elements/Icons/NoAcceptIcon";
import AcceptIcon from "../Elements/Icons/AcceptIcon";

const Title = styled.p`
    margin: 0;
    color: rgba(0, 0, 0, 0.9);
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    margin-bottom: 40px;
    
    @media (max-width: 435px) {
        font-size: 22px;
        margin-bottom: 24px;
    }
`;

const Subtitle = styled.p`
    margin: 0;
    color: rgba(0, 0, 0, 0.9);
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    margin-bottom: 40px;
    
    @media (max-width: 435px) {
        margin-bottom: 24px;
    }
`;

const ItemWrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    margin-bottom: 24px;
`;

const ItemTitle = styled.div`
    display: flex;
    align-items: center;
    margin: 0;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 8px;
    
    @media (max-width: 435px) {
        flex-direction: column-reverse;
        align-items: flex-start;
    }
`;

const ItemValue = styled.div`
    display: flex;
    margin: 0;
    color: rgba(0, 0, 0, 0.9);
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
`;

const AcceptValueText = styled.div`
    margin: 0;
    color: #FF5145;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 24px;
    margin-top: -12px;
    cursor: pointer;
`;

const Form = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 40px 30px;
    width: 436px;
    background: #FFFFFF;
    box-shadow: 0 4px 30px rgba(194, 201, 206, 0.35);
    border-radius: 24px;
    margin-top: 100px;
    position: relative;
    z-index: 999;
    
    @media (max-width: 435px) {
        margin-top: 0;
        width: 100%;
        padding: 24px 16px;
        border-radius: 0 0 24px 24px;
    }
`;

const UnderFormText = styled.a`
    margin: 24px 0;
    text-decoration: none;
    font-weight: 600;
    font-size: 16px;
    height: 52px;
    width: 436px;
    line-height: 160%;
    color: #FF5145;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    transition: all 300ms ease-in-out;
    cursor: pointer;

    &:hover, &:active {
        background: rgba(255, 81, 69, 0.3);
        color: #FFFFFF;
    }
    
    @media (max-width: 435px) {
        width: calc(100% - 35px);
    }    
`;

const AcceptContainer = styled.div`
    display: flex;
    align-items: center;
    margin: 0;
    color: ${({isAccept}) => isAccept ? "#46C54B" : "#F19B35"};
    background: ${({isAccept}) => isAccept ? "rgba(70, 207, 75, 0.1)" : "rgba(241, 155, 53, 0.1)"};
    border-radius: 100px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    padding: 6px 8px;
    
    svg {
        margin-right: 8px;
        width: 21px;
        height: 21px;
    }
`;

/**
 * Страница отображения своих данных
 */
const MyDataPage = observer(() => {
    const navigate = useNavigate();
    const store = useRootStore();

    /**
     * Определение типа двухфакторной авторизации
     */
    const getTwoFactorAuthTypeAlias = () => {
        switch(store.userStore.twoFactorAuthType) {
            case "EMAIL":
                return "По Email";
            case "MSISDN":
                return "По SMS";
            case "OFF":
                return "Не настроен";
        }
    };

    return (
        <>
            <Form>
                <Title>Информационные системы Московской области</Title>
                <Subtitle>Основная информация</Subtitle>
                <ItemWrapper>
                    <ItemTitle>
                        <span>ФИО</span>
                    </ItemTitle>
                    <ItemValue>{store.userStore.userName}</ItemValue>
                </ItemWrapper>
                <EditablePhoneField showAccept={store.userStore.showAcceptStatusPhoneNumber}
                                    accept={store.userStore.acceptPhoneNumber}
                                    phone={store.userStore.phoneNumber}
                                    onChange={val => store.userStore.onChangePhoneNumber(navigate, val)}/>
                {
                    store.userStore.showAcceptPhoneNumber &&
                    <AcceptValueText onClick={() => store.userStore.onAcceptPhoneNumber(navigate)}>
                        Подтвердить
                    </AcceptValueText>
                }
                <EditableEmailField showAccept={store.userStore.showAcceptStatusEmailAddress}
                                    accept={store.userStore.acceptEmailAddress}
                                    email={store.userStore.emailAddress}
                                    onChange={val => store.userStore.onChangeEmailAddress(navigate, val)}/>
                {
                    store.userStore.showAcceptEmailAddress &&
                    <AcceptValueText onClick={() => store.userStore.onAcceptEmailAddress(navigate)}>
                        Подтвердить
                    </AcceptValueText>
                }
                <ItemWrapper>
                    <ItemTitle>
                        <span>Двухэтапный вход</span>
                    </ItemTitle>
                    <ItemValue>
                        <AcceptContainer isAccept={store.userStore.twoFactorAuthType !== "OFF"}>
                            {
                                store.userStore.twoFactorAuthType === "OFF" ?
                                    <NoAcceptIcon/>
                                :
                                    <AcceptIcon/>
                            }
                            {getTwoFactorAuthTypeAlias()}
                        </AcceptContainer>
                    </ItemValue>
                </ItemWrapper>
                <ItemWrapper>
                    <ItemTitle>
                        <span>Связь  учетной записью ЕСИА</span>
                    </ItemTitle>
                    <ItemValue>
                        <AcceptContainer isAccept={store.userStore.esiaId}>
                            {!store.userStore.esiaId ? <NoAcceptIcon/> : <AcceptIcon/>}
                            {store.userStore.esiaId ? "Настроена" : "Не настроена"}
                        </AcceptContainer>
                    </ItemValue>
                </ItemWrapper>
                <ItemWrapper>
                    <ItemTitle>
                        <span>Класс доверия учетной записи</span>
                    </ItemTitle>
                    <ItemValue>
                        <AcceptContainer isAccept={store.userStore.esiaTrusted}>
                            {!store.userStore.esiaTrusted ? <NoAcceptIcon/> : <AcceptIcon/>}
                            {store.userStore.esiaTrusted ? "Подтверждённая" : "Не доверенное"}
                        </AcceptContainer>
                    </ItemValue>
                </ItemWrapper>
                <Button title={"Изменить"}
                        type={"border_red"}
                        onClick={() => navigate(routes.infoEdit.path)}
                        margin={"16px 0 0 0"}/>
                <Button title={"Назад"}
                        onClick={() => navigate(routes.main.path)}
                        margin={"12px 0 0 0"}/>
            </Form>
            {
                store.userStore.user?.hasPassword &&
                <UnderFormText onClick={() => navigate(routes.changePassword.path)}>
                    Сменить пароль
                </UnderFormText>
            }
        </>
    )
});

export default MyDataPage;
