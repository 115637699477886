import React, {useState} from "react";
import styled from "styled-components";

const CheckboxWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 12px;
    position: relative;
    cursor: pointer;
    ${({margin}) => margin ? `margin: ${margin};` : ""}
    
    input[type=checkbox] {
        display: none;
    }
    input[type=checkbox]:checked + label:before {
        background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%3E%0A%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M5%203H19C20.1%203%2021%203.9%2021%205V19C21%2020.1%2020.1%2021%2019%2021H5C3.9%2021%203%2020.1%203%2019V5C3%203.9%203.9%203%205%203ZM16.6%207.6L18%209L10%2017L6%2013L7.4%2011.6L10%2014.2L16.6%207.6Z%22%20fill%3D%22%23FF5145%22%2F%3E%0A%3C%2Fsvg%3E");
        background-repeat: no-repeat;
    }
    label:hover:before {
        filter: brightness(120%);
    }
`;

const Label = styled.label`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    position: relative;
    padding-left: 38px;
    font-weight: 600;
    font-size: 16px;
    line-height: 160%;
    color: rgba(0, 0, 0, 0.9);
    cursor: pointer;
   
    &:before {
        content: "";
        display: inline-block;
        width: 24px;
        height: 24px;
        position: absolute;
        left: 0;
        top: 0;
        background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%3E%0A%3Cpath%20d%3D%22M19%205V19H5V5H19ZM19%203H5C3.9%203%203%203.9%203%205V19C3%2020.1%203.9%2021%205%2021H19C20.1%2021%2021%2020.1%2021%2019V5C21%203.9%2020.1%203%2019%203Z%22%20fill%3D%22%23FF5145%22%20fill-opacity%3D%220.4%22%2F%3E%0A%3C%2Fsvg%3E");
        background-repeat: no-repeat;
    }
`;

const Checkbox = ({title, id, onChange, value, margin}) => {
    return (
        <CheckboxWrapper margin={margin}>
            <input id={id}
                   onClick={() => onChange(!value)}
                   type="checkbox"
                   value={value}/>
            <Label htmlFor={id}>{title}</Label>
        </CheckboxWrapper>
    )
};

export default Checkbox;
