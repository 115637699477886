import React from "react";

const NoAcceptIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
            <circle cx="9" cy="9" r="6.75" fill="#F19B35"/>
            <path d="M6 9H12" stroke="white" strokeWidth="1.2"/>
        </svg>
    )
};

export default NoAcceptIcon;
