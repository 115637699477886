import React, {useState} from "react";
import styled from "styled-components";
import Button from "../Elements/Button";
import {useNavigate} from "react-router";
import routes from "../routes";
import {useRootStore} from "../RootStoreProvider";
import {observer} from "mobx-react";
import ModalAccept from "../Elements/ModalAccept";

const Page = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    
    @media (max-width: 435px) {
        margin-top: 0;
    }
`;

const Title = styled.p`
    margin: 0;
    color: rgba(0, 0, 0, 0.9);
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    margin-bottom: 40px;
    
    @media (max-width: 435px) {
        font-size: 22px;
        margin-bottom: 24px;
    }
`;

const Subtitle = styled.div`
    color: rgba(0, 0, 0, 0.9);
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    margin-bottom: 40px;

    @media (max-width: 435px) {
        margin-bottom: 34px;
    }
    
    p {
        font-weight: 600;
        color: #FF5145;
        display: block;
        margin: 4px 0 0 0;
    }
`;

const Element = styled.div`
    margin: 0;
    color: rgba(0, 0, 0, 0.9);
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    border-radius: 8px;
    padding: 10px 12px;
    width: 100%;
    cursor: pointer;
    position: relative;
    
    &:hover {
        background: rgba(255, 81, 69, 0.05);
    }
`;

const Indicator = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    font-size: 12px;
    line-height: 15px;
    border-radius: 50px;
    width: 20px;
    height: 20px;
    top: 0;
    right: calc(50% - 90px);
    background: #44C915;
`;

const Form = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 40px 30px;
    width: 436px;
    background: #FFFFFF;
    box-shadow: 0 4px 30px rgba(194, 201, 206, 0.35);
    border-radius: 24px;
    position: relative;
    z-index: 999;
    
    @media (max-width: 435px) {
        width: 100%;
        padding: 24px 16px;
        border-radius: 0 0 24px 24px;
    }
`;

const UnderFormText = styled.a`
    margin: 24px 0;
    text-decoration: none;
    font-weight: 600;
    font-size: 16px;
    height: 52px;
    line-height: 160%;
    color: #FF5145;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    transition: all 300ms ease-in-out;
    cursor: pointer;
    
    &:hover, &:active {
        background: rgba(255, 81, 69, 0.3);
        color: #FFFFFF;
    }
`;

const OrganizationName = styled.div`
    margin: 4px 0 0 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: rgba(0, 0, 0, 0.5);
`;

/**
 * Главная страница авторизованного пользователя
 */
const MainPage = observer(() => {
    const navigate = useNavigate();
    const store = useRootStore();
    const [openDeleteModal, setOpenDelete] = useState(false);

    /**
     * Удаление своей учетки
     */
    const onAcceptDelete = () => {
        setOpenDelete(false);
        store.userStore.removeYourself();
    };

    if (!store.userStore.user)
        return null;

    return (
        <Page>
            <Form>
                <Title>Информационные системы Московской области</Title>
                <Subtitle isOrganization={store.userStore.isOrganization}>Текущая учетная запись: <p>{store.userStore.userName}</p>
                {
                    store.userStore.isOrganization &&
                    <OrganizationName>{store.userStore.organizationRole}{store.userStore.organizationName}</OrganizationName>
                }
                </Subtitle>
                <Element onClick={() => navigate(routes.info.path)}>Мои сведения</Element>
                <Element onClick={store.userStore.logout}>
                    {
                        store.userStore.organization ?
                            "Переключиться на физическое лицо"
                        :
                            "Переключиться на юридическое лицо"
                    }
                </Element>
                <Element onClick={() => navigate(routes.twoStepEntrySettings.path)}>Настроить двухэтапный вход</Element>
                <Element onClick={() => navigate(routes.requests.path)}>
                    Объединение учетных записей
                    {
                        store.userStore.incomingMergeRequests.length > 0 &&
                        <Indicator>{store.userStore.incomingMergeRequests.length}</Indicator>
                    }
                </Element>
                <Element onClick={store.userStore.logout}>Выйти</Element>
            </Form>
            <UnderFormText onClick={() => setOpenDelete(true)}>
                Удалить учетную запись
            </UnderFormText>
            {
                openDeleteModal &&
                <ModalAccept onClose={() => setOpenDelete(false)}
                             onAccept={onAcceptDelete}
                             text={"Вы действительно хотите удалить аккаунт?"}/>
            }
        </Page>
    )
});

export default MainPage;
