import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import Input from "../Elements/Input";
import Button from "../Elements/Button";
import {useNavigate} from "react-router";
import {useRootStore} from "../RootStoreProvider";
import Data from "./data";
import TextTimer from "../Elements/TextTimer";
import {observer} from "mobx-react";

const Subtitle = styled.div`
    margin: 24px 0 0 0;
    color: rgba(0, 0, 0, 0.9);
    font-weight: 600;
    font-size: 16px;
    line-height: 160%;
    text-align: center;
`;

const Msg = styled.p`
    color: rgba(0, 0, 0, 0.5);
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    margin-bottom: 24px;
    margin-top: 0;
    
    b {
        color: rgba(0, 0, 0, 0.9);
    }
`;

const Time = styled.p`
    color: rgba(0, 0, 0, 0.9);
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-top: 0;
    margin-bottom: 4px;
`;

const NewCode = styled.p`
    font-weight: 600;
    font-size: 16px;
    line-height: 160%;
    color: #FF5145;
    cursor: pointer;
    margin: 0;
    opacity: ${({disabled}) => disabled ? 0.3 : 1};
    cursor: ${({disabled}) => disabled ? "default" : "cursor"};
`;

const Form = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 40px 30px;
    width: 436px;
    background: #FFFFFF;
    box-shadow: 0 4px 30px rgba(194, 201, 206, 0.35);
    border-radius: 24px;
    margin-bottom: 16px;
    position: relative;
    z-index: 999;
    
    @media (max-width: 435px) {
        width: 100%;
        padding: 24px 16px;
    }
`;

const PageTitle = styled.p`
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    color: rgba(0, 0, 0, 0.9);
    margin: 0 0 24px 0;
    
    @media (max-width: 435px) {
        font-size: 22px;    
        margin: 0 16px 24px 16px;
    }
`;

const Page = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    
    @media (max-width: 435px) {
        margin-top: 24px;
        flex-grow: 1;
    }
`;

const ErrorWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #D61C0F;
    margin-bottom: 8px;
`;

const Dot = styled.div`
    width: 6px;
    height: 6px;
    background: #D61C0F;
    margin-right: 8px;
    border-radius: 50px;
`;

const Controls = styled.div`
    width: 100%;
    padding: 8px 0 0 0;
    @media (max-width: 435px) {
        padding: 8px 16px 0 16px;
    }
`;

const Container = styled.div`
    @media (max-width: 435px) {
        padding: 0 16px;
    }
`;

/**
 * Страница подтверждения регистрации
 */
const RegistrationAcceptPage = observer(() => {
    const navigate = useNavigate();
    const store = useRootStore();
    const data = useRef(new Data(navigate, store.userStore, store.toastStore));
    const defaultSeconds = 300;

    useEffect(() => {
        data.current.getRegistrationObj();
    }, []);

    const [secondEmail, setSecondEmail] = useState(defaultSeconds);
    const [secondPhone, setSecondPhone] = useState(defaultSeconds);

    /**
     * Получение новго кода для почты
     */
    const getNewCodeEmail = () => {
        if (secondEmail <= 0) {
            setSecondEmail(defaultSeconds);
        }
    };

    /**
     * Получение новго кода для  телефона
     */
    const getNewCodePhone = () => {
        if (secondPhone <= 0) {
            setSecondPhone(defaultSeconds);
        }
    };

    /**
     * Получение почты для отображения
     */
    const getEmail = () => {
        if (data.current.registrationObg.emailAddress.length > 30) {
            return data.current.registrationObg.emailAddress.substring(0, 30);
        }
        return data.current.registrationObg.emailAddress;
    };

    return (
        <Page>
            <PageTitle>Подтвердите свои данные</PageTitle>
            {
                data.current.registrationObg &&
                data.current.registrationObg.msisdn &&
                (
                    !data.current.registrationObg.msisdnConfirmed ?
                        <Form>
                            <Msg>На ваш телефон <b>{data.current.registrationObg.msisdn}</b> направлено SMS-уведомление с кодом подтверждения</Msg>
                            <Input placeholder="Введите код из SMS"
                                   maxLength={6}
                                   margin={data.current.errorConfirmPhone ? "4px" : "12px"}
                                   isError={data.current.errorConfirmPhone}
                                   onChange={v => data.current.onChangeInput("codePhone", v)}
                                   fontSize={"24px"}
                                   height={"60px"}
                                   textAlign={"center"}
                                   inputType={"code"}
                                   value={data.current.codePhone}/>
                            {
                                data.current.errorConfirmPhone &&
                                <ErrorWrapper>
                                    <Dot/>Неверный код
                                </ErrorWrapper>
                            }
                            {
                                secondPhone > 0 &&
                                <Time>Через <TextTimer seconds={secondPhone} onCancel={() => setSecondPhone(0)}/> код можно запросить повторно</Time>
                            }
                            <NewCode disabled={secondPhone > 0}
                                     onClick={getNewCodePhone}>
                                Запросить новый код
                            </NewCode>
                        </Form>
                    :
                        <Container>
                            <Form style={{alignItems: 'center'}}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="81" height="80" viewBox="0 0 81 80" fill="none">
                                    <path d="M30.4998 40.0001L37.1665 46.6667L52.1665 31.6667M24.9457 12.7291C27.6252 12.5152 30.1689 11.4616 32.2147 9.71815C36.9889 5.64962 44.0108 5.64962 48.7849 9.71815C50.8308 11.4616 53.3745 12.5152 56.0539 12.7291C62.3067 13.228 67.2719 18.1932 67.7709 24.446C67.9847 27.1254 69.0383 29.6691 70.7818 31.715C74.8503 36.4892 74.8503 43.511 70.7818 48.2852C69.0383 50.331 67.9847 52.8747 67.7709 55.5542C67.2719 61.8069 62.3067 66.7721 56.0539 67.2711C53.3745 67.4849 50.8308 68.5386 48.7849 70.282C44.0108 74.3505 36.9889 74.3505 32.2147 70.282C30.1689 68.5386 27.6252 67.4849 24.9457 67.2711C18.693 66.7721 13.7278 61.8069 13.2288 55.5542C13.015 52.8747 11.9614 50.331 10.2179 48.2852C6.14937 43.511 6.14937 36.4892 10.2179 31.715C11.9614 29.6691 13.015 27.1254 13.2288 24.446C13.7278 18.1932 18.693 13.228 24.9457 12.7291Z" stroke="#4EC870" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                <Subtitle>Телефон успешно подтвержден</Subtitle>
                            </Form>
                        </Container>
                )
            }
            {
                data.current.registrationObg &&
                data.current.registrationObg.emailAddress &&
                (
                    !data.current.registrationObg.emailAddressConfirmed ?
                        <Form>
                            <Msg>На ваш адрес электронной почты <b title={data.current.registrationObg.emailAddress}>{getEmail()}</b> направлено письмо с кодом подтверждения</Msg>
                            <Input placeholder="Введите код из письма"
                                   onChange={v => data.current.onChangeInput("codeEmail", v)}
                                   fontSize={"24px"}
                                   maxLength={6}
                                   margin={data.current.errorConfirmEmail ? "4px" : "12px"}
                                   isError={data.current.errorConfirmEmail}
                                   textAlign={"center"}
                                   inputType={"code"}
                                   height={"60px"}
                                   value={data.current.codeEmail}/>
                            {
                                data.current.errorConfirmEmail &&
                                <ErrorWrapper>
                                    <Dot/>Неверный код
                                </ErrorWrapper>
                            }
                            {
                                secondEmail > 0 &&
                                <Time>Через <TextTimer seconds={secondEmail} onCancel={() => setSecondEmail(0)}/> код можно запросить повторно</Time>
                            }
                            <NewCode disabled={secondEmail > 0}
                                     onClick={getNewCodeEmail}>
                                Запросить новый код
                            </NewCode>
                        </Form>
                    :
                        <Container>
                            <Form style={{alignItems: 'center'}}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="81" height="80" viewBox="0 0 81 80" fill="none">
                                    <path d="M30.4998 40.0001L37.1665 46.6667L52.1665 31.6667M24.9457 12.7291C27.6252 12.5152 30.1689 11.4616 32.2147 9.71815C36.9889 5.64962 44.0108 5.64962 48.7849 9.71815C50.8308 11.4616 53.3745 12.5152 56.0539 12.7291C62.3067 13.228 67.2719 18.1932 67.7709 24.446C67.9847 27.1254 69.0383 29.6691 70.7818 31.715C74.8503 36.4892 74.8503 43.511 70.7818 48.2852C69.0383 50.331 67.9847 52.8747 67.7709 55.5542C67.2719 61.8069 62.3067 66.7721 56.0539 67.2711C53.3745 67.4849 50.8308 68.5386 48.7849 70.282C44.0108 74.3505 36.9889 74.3505 32.2147 70.282C30.1689 68.5386 27.6252 67.4849 24.9457 67.2711C18.693 66.7721 13.7278 61.8069 13.2288 55.5542C13.015 52.8747 11.9614 50.331 10.2179 48.2852C6.14937 43.511 6.14937 36.4892 10.2179 31.715C11.9614 29.6691 13.015 27.1254 13.2288 24.446C13.7278 18.1932 18.693 13.228 24.9457 12.7291Z" stroke="#4EC870" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                <Subtitle>Электронная почта успешно подтверждена</Subtitle>
                            </Form>
                        </Container>
                )
            }
            <Controls>
                {
                    data.current.registrationObg &&
                    <Button title={"Завершить регистрацию"}
                            isDisabled={!data.current.registrationObg.msisdnConfirmed && !data.current.registrationObg.emailAddressConfirmed}
                            margin={"0 0 12px 0"}
                            onClick={data.current.finishRegistration}/>
                }
                {
                    data.current.registrationObg &&
                    !data.current.registrationObg.msisdnConfirmed &&
                    !data.current.registrationObg.emailAddressConfirmed &&
                    <Button title={"Отменить"}
                            type={"transparent_red"}
                            margin={"0 0 24px 0"}
                            onClick={data.current.cancelRegistration}/>
                }
            </Controls>
        </Page>
    )
});

export default RegistrationAcceptPage;
