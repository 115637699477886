import {makeAutoObservable} from "mobx"

const TIMEOUT = 2000;

/**
 * Хранилище для всплывающих уведомлений
 */
export default class ToastStore {
    toast = null;
    rootStore = null;
    toastTimeoutId = null;

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    createToast = (title, message, type) => {
        if (this.toastTimeoutId) {
            clearTimeout(this.toastTimeoutId);
        }
        this.toast = {
            title,
            message,
            type
        };
        this.toastTimeoutId = setTimeout(this.closeToast, TIMEOUT);
    };

    closeToast = () => {
        clearTimeout(this.toastTimeoutId);
        this.toast = null;
    };

    showSuccess = (title, message) => this.createToast(title, message, "success");

    showInfo = (title, message) => this.createToast(title, message, "info");

    showError = (title, message) => this.createToast(title, message, "error");
}
