import React, {useCallback, useEffect, useRef} from "react";
import {useRootStore} from "../RootStoreProvider";
import PhoneInput from "../Elements/PhoneInput";
import EmailInput from "../Elements/EmailInput";
import Checkbox from "../Elements/Checkbox";
import {useLocation, useNavigate} from "react-router";
import Button from "../Elements/Button";
import debounce from "lodash.debounce";
import styled from "styled-components";
import Input from "../Elements/Input";
import {observer} from "mobx-react";
import Data from "./data";
import {AUTH_URL, BASE_URL} from "../config";
import EsiaIcon from "../Elements/Icons/EsiaIcon";

const Title = styled.div`
    margin: 0;
    color: rgba(0, 0, 0, 0.9);
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    margin-bottom: 40px;
    
    @media (max-width: 435px) {
        font-size: 22px;
        margin-bottom: 24px; 
    }
`;

const Subtitle = styled.div`
    margin: 0;
    color: rgba(0, 0, 0, 0.9);
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    width: 100%;
    margin-bottom: 40px;
    
    @media (max-width: 435px) {
        margin-bottom: 24px;
    }
`;

const GenerateText = styled.div`
    font-weight: 600;
    font-size: 16px;
    line-height: 160%;
    color: #FF5145;
    margin-top: 4px;
    margin-bottom: 12px;
    cursor: pointer;
`;

const ErrorWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #D61C0F;
    margin-bottom: 12px;
`;

const Dot = styled.div`
    width: 6px;
    height: 6px;
    background: #D61C0F;
    margin-right: 8px;
    border-radius: 50px;
`;

const Form = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 40px 30px;
    width: 436px;
    background: #FFFFFF;
    box-shadow: 0 4px 30px rgba(194, 201, 206, 0.35);
    border-radius: 24px;
    margin-top: 100px;
    position: relative;
    z-index: 999;
    
    @media (max-width: 435px) {
        margin-top: 0;
        width: 100%;
        padding: 24px 16px;
        border-radius: 0 0 24px 24px;
    }
`;

const UnderFormText = styled.a`
    margin: 24px 0;
    text-decoration: none;
    font-weight: 600;
    font-size: 16px;
    height: 52px;
    width: 436px;
    line-height: 160%;
    color: #FF5145;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    transition: all 300ms ease-in-out;
    cursor: pointer;

    &:hover, &:active {
        background: rgba(255, 81, 69, 0.3);
        color: #FFFFFF;
    }

    @media (max-width: 435px) {
        width: calc(100% - 35px);
    }
`;

/**
 * СТраница регистрации
 */
const RegistrationPage = observer(() => {
    const navigate = useNavigate();
    const store = useRootStore();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const data = useRef(new Data(navigate, store.userStore, params.get("redirect_uri"), store.toastStore));

    useEffect(() => {
        data.current.checkRegistration();
    }, []);

    /**
     * Сохранение изменении
     */
    const saveChanges = useCallback(debounce(data.current.updateRegistration, 500), []);

    /**
     * Обработчик ввода значений в поля формы
     */
    const onChane = (key, value) => {
        data.current[key] = value;
        saveChanges();
    };

    /**
     * Генерация пароля
     */
    const generatePass = async () => {
        const password = await store.userStore.generatePassword();
        if (!password) {
            return;
        }

        data.current.password = password;
        data.current.passwordRepeat = password;
    };

    /**
     * Блокировка воода проблема в пароль
     */
    const onKeyPress = e => {
        if (e.key === ' ') {
            e.preventDefault();
        }
    };

    /**
     * Переход на авторизаци через ЕСИА
     */
    const goToEsia = () => {
        window.location = `${BASE_URL}/auth/login?login_type=esia`;
    };

    return (
        <>
            <Form>
                <Title>Информационные системы Московской области</Title>
                <Subtitle>Создание новой учетной записи</Subtitle>
                <Input placeholder="Введите вашу фамилию"
                       onChange={v => onChane("surename", v)}
                       value={data.current.surename}/>
                <Input placeholder="Введите ваше имя"
                       onChange={v => onChane("name", v)}
                       value={data.current.name}/>
                <Input placeholder="Введите ваше отчество"
                       onChange={v => onChane("patronymic", v)}
                       value={data.current.patronymic}/>
                <PhoneInput placeholder="+7 (999) 999-99-99"
                            isError={
                                !!data.current.errorFields.find(i => i.field === "msisdn") ||
                                (
                                    data.current.msisdn &&
                                    data.current.msisdn?.replace(/\D/g, '').length > 1 &&
                                    data.current.msisdn?.replace(/\D/g, '').length < 11
                                )
                            }
                            onChange={v => onChane("msisdn", v)}
                            value={data.current.msisdn}/>
                <EmailInput placeholder="Введите электронную почту"
                            isError={!!data.current.errorFields.find(i => i.field === "emailAddress")}
                            onChange={v => onChane("emailAddress", v)}
                            value={data.current.emailAddress}/>
                <Input placeholder="Введите пароль"
                       onChange={v => data.current.password = v}
                       type={"password"}
                       margin={data.current.isPasswordNotValid ? "12px" : "0"}
                       onKeyPress={onKeyPress}
                       isError={data.current.isPasswordNotValid}
                       value={data.current.password}/>
                {
                    data.current.isPasswordNotValid &&
                    <ErrorWrapper>
                        <Dot/>Пароль должен содержать минимум 6 символов
                    </ErrorWrapper>
                }
                <GenerateText onClick={generatePass}>
                    Сгенерировать сложный пароль
                </GenerateText>
                <Input placeholder="Введите пароль еще раз"
                       onChange={v => data.current.passwordRepeat = v}
                       type={"password"}
                       isRepeatPassword
                       onKeyPress={onKeyPress}
                       isError={data.current.password !== data.current.passwordRepeat}
                       value={data.current.passwordRepeat}/>
                {
                    data.current.password !== data.current.passwordRepeat &&
                    <ErrorWrapper>
                        <Dot/>Пароли не совпадают
                    </ErrorWrapper>
                }
                <Checkbox value={data.current.access}
                          onChange={v => data.current.access = v}
                          margin={"16px 0"}
                          title={"Я даю согласие на обработку моих персональных данных"}
                          id={"access"}/>
                <Button title={"Создать"}
                        isDisabled={data.current.disabledForm}
                        margin={"12px 0 0 0"}
                        onClick={data.current.registration}/>
                <Button title={"Войти через ЕСИА"}
                        bgColor={"blue"}
                        margin={"12px 0 0 0"}
                        icon={<EsiaIcon/>}
                        onClick={goToEsia}/>
            </Form>
            <UnderFormText href={AUTH_URL}>Авторизоваться</UnderFormText>
        </>
    )
});

export default RegistrationPage;
